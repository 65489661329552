/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import {
  Routes,
  Route,
  redirect,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import { NotFound } from 'pages/NotFound';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
// import { Shop } from 'pages/Shop';
import ShopDetail from 'pages/Shop/Detail';
import Reconhecer from 'pages/Reconhecer';
import Extrato from 'pages/Extrato';
import Historico from 'pages/Historico';
import Faq from 'pages/Faq';
import Regulamento from 'pages/Regulamento';
import Convidar from 'pages/Convidar';
// import { ShopTeaser } from 'pages/Shop/teaser';
import MissaoDestaque from 'pages/MissaoDestaque';
import { ValidarDestaque } from 'pages/ValidarDestaque';
import { Catalog } from 'pages/Catalog';
// import ValidarDestaqueDetail from 'pages/ValidarDestaque/Detail';
import MissaoDestaqueCreate from 'pages/MissaoDestaque/Create';
import GaleriaReconhecimento from 'pages/GaleriaReconhecimento';
import DestaquesCultura from 'pages/DestaquesCultura';
import { DestaquesCulturaDetail } from 'pages/DestaquesCultura/detail';
import { Manifesto } from 'pages/Manifesto';
import QuadroEquipe from 'pages/QuadroEquipe';
import CustomNavigationClient from 'sso/azure/CustomNavigationClient';
import { MsalProvider } from '@azure/msal-react';
// import Rede from 'pages/Rede';
// import ValidarDestaqueDetail from 'pages/ValidarDestaque/Detail';

export const RouteList = ({ msalInstance }: any) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute redirectTo="/login">
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route
            path="/reconhecer"
            element={
              <PrivateRoute redirectTo="/login">
                <Reconhecer />
              </PrivateRoute>
            }
          />

          <Route
            path="/reconhecer/:id"
            element={
              <PrivateRoute redirectTo="/login">
                <Reconhecer />
              </PrivateRoute>
            }
          />

          <Route
            path="/convidar"
            element={
              <PrivateRoute redirectTo="/login">
                <Convidar />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/missao-destaque"
          element={<Navigate to="/realizacao-destaque" />}
        />

        <Route
          path="/realizacao-destaque"
          element={
            <PrivateRoute redirectTo="/login">
              <MissaoDestaque />
            </PrivateRoute>
          }
        >
          <Route
            path="/realizacao-destaque/:id"
            element={
              <PrivateRoute redirectTo="/login">
                <MissaoDestaqueCreate />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/validar-destaque"
          element={
            <PrivateRoute redirectTo="/login">
              <ValidarDestaque />
            </PrivateRoute>
          }
        >
          <Route
            path=":id"
            element={
              <PrivateRoute redirectTo="/login">
                <MissaoDestaqueCreate />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/extrato"
          element={
            <PrivateRoute redirectTo="/login">
              <Extrato />
            </PrivateRoute>
          }
        />

        <Route
          path="/quadro-equipe"
          element={
            <PrivateRoute redirectTo="/login">
              <QuadroEquipe />
            </PrivateRoute>
          }
        />

        {/* <Route
      path="/rede-joga-junto"
      element={
        <PrivateRoute redirectTo="/login">
          <Rede />
        </PrivateRoute>
      }
    /> */}

        <Route
          path="/historico"
          element={
            <PrivateRoute redirectTo="/login">
              <Historico />
            </PrivateRoute>
          }
        />

        <Route
          path="/manifesto"
          element={
            <PrivateRoute redirectTo="/login">
              <Manifesto />
            </PrivateRoute>
          }
        />

        <Route
          path="/experiencias"
          element={
            <PrivateRoute redirectTo="/login">
              <Catalog />
            </PrivateRoute>
          }
        >
          <Route path=":id" element={<ShopDetail />} />
        </Route>

        <Route
          path="/galeria"
          element={
            <PrivateRoute redirectTo="/login">
              <GaleriaReconhecimento />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/destaques-cultura"
          element={
            <PrivateRoute redirectTo="/login">
              <DestaquesCultura />
            </PrivateRoute>
          }
        >
          <Route path=":behavior/:id" element={<DestaquesCulturaDetail />} />
        </Route>

        <Route path="/ajuda" element={<Faq />} />

        <Route path="/regulamento" element={<Regulamento />} />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </MsalProvider>
  );
};
