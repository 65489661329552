import { Countdown } from 'components/countdown';
import { useAuth } from 'context/AuthContext';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  getTaskCycles,
  getPlayerInfo,
  getTasks,
  getTasksManager,
} from 'services/dashboard.service';
import { GetTaskLevelLabel, GetTaskStatusLabel } from 'utils/strings';

import ImgFeedback from 'assets/img/comportamento/bg-missoes-not-found.png';
import Button from 'components/ui/button';
import { getChallengeHome } from 'services/challenges.service';

export function Company() {
  const navigate = useNavigate();

  // const [tasks, setTasks] = useState<any>();
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [data, setData] = useState<any>();
  const [taskCycles, setTaskCycles] = useState<any>();
  const [myTasks, setMyTasks] = useState<any[]>();
  const [cycleActive, setCycleActive] = useState<string>();

  useEffect(() => {
    async function loadData() {
      const data = await getChallengeHome();
      setData(data);
    }

    loadData();
  }, []);
  
  function addBreakLine(str:string) {
    return str.replace(/\n/g, "<br />");
  }

  return (
    <div className="flex flex-col">
      <>
        <div>
          <p className="text-white text-xs lg:text-sm block mb-6 font-thin">
            Acompanhe aqui atividades extras disponíveis durante este ciclo
          </p>
        </div>

        {data &&
          data
            .filter((item: any) => !!item?.extra?.button && !!item?.extra?.link)
            .map((item: any) => (
              <div key={item._id} className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center mb-4">
                <div className="bg-maastricht-blue rounded-md px-5 py-3 text-sm md:w-[64%]">
                  <p className="font-bold text-lg text-yellow">
                    {item.challenge}
                  </p>
                  <p className="font-thin" dangerouslySetInnerHTML={{__html: addBreakLine(item.description)}}></p>
                </div>

                <div className="flex justify-center flex-1 mx-2">
                  <a
                    className="text-center block visited:no-underline focus:no-underline focus:text-white hover:text-white hover:no-underline hover:ring hover:ring-white rounded-md shadow-xl font-bold px-4 bg-purple text-white uppercase py-3 text-sm lg:-mr-8"
                    href={item.extra.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.extra.button}
                  </a>
                </div>
              </div>
            ))}

        {/* <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center">
          <div className="bg-maastricht-blue rounded-md px-5 py-3 text-sm md:w-[64%]">
            <p className="font-bold text-lg text-yellow">Convide seus amigos</p>
            <p className="font-thin">
              Cada amigo que entrar no Joga Junto com seu convite, você recebe 5
              pontos extras
            </p>
          </div>

          <div className="flex justify-center flex-1">
            <Button
              color="primary"
              className="uppercase py-2 text-sm  lg:-mr-8"
              onClick={() => navigate('/convidar')}
            >
              Convide seus amigos
            </Button>
          </div>
        </div> */}
      </>
    </div>
  );
}
