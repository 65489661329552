import { useNavigate, useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Box from 'components/ui/box';
import ReactModal from 'react-modal';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TbSearch } from 'react-icons/tb';
import InputMask from 'react-input-mask';
import { findPlayer } from 'services/profile.service';
import { User } from 'types/user';
import { RiCloseCircleFill, RiCloseLine } from 'react-icons/ri';
import { useAuth } from 'context/AuthContext';
// import { actionLog } from 'services/actionlog.service';
import { BiCircle, BiInfoCircle } from 'react-icons/bi';
import {
  deleteMissionFeedback,
  getPlayerInfo,
  getServerTime,
  getTaskById,
  getTaskCycles,
  submitTask,
  updateTask,
} from 'services/dashboard.service';
import { UserAvatar } from 'components/user-avatar';
import { format, utcToZonedTime } from 'date-fns-tz';
import { IoMdCloseCircle } from 'react-icons/io';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.png';
import { toast } from 'react-toastify';

const maskTemplate = 'F9999999';

export default function MissaoDestaqueCreate() {
  const navigate = useNavigate();
  const params = useParams();

  const { user, refreshUser, currentCycle } = useAuth();
  const [playerInfo, setPlayerInfo] = useState<any>();

  const searchRef = useRef<any>();
  const messageRef = useRef<any>();
  const titleRef = useRef<any>();
  const periodStartRef = useRef<any>();
  const periodEndRef = useRef<any>();
  const necRef = useRef<any>();

  const [isOpen, setIsOpen] = useState(true);

  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [target, setTarget] = useState<User[]>();

  const [teamDistribution, setTeamDistribution] = useState<any>({});

  // const [behavior, setBehavior] = useState<string>();
  // const [coin, setCoin] = useState<string>();

  const [periodStart, setPeriodStart] = useState<any>();
  const [periodEnd, setPeriodEnd] = useState<any>();
  const [nec, setNec] = useState<any>();

  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();

  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [step, setStep] = useState(0);

  const [submitError, setSubmitError] = useState<string>();

  const [mask, setMask] = useState('');

  const [currentTask, setCurrentTask] = useState<any>();

  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();

  ReactModal.setAppElement('#modal');

  const getUserInfo = useCallback(async () => {
    try {
      const playerInfo = await getPlayerInfo(user?._id);

      if (playerInfo) {
        setPlayerInfo(playerInfo);
        return playerInfo;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }, [user]);

  function handleChange() {
    if (searchRef.current) {
      const value = searchRef.current.value.toUpperCase();
      if (value.length > 1) {
        if (value[0].toUpperCase() === 'F' && !isNaN(value[1])) {
          setMask(maskTemplate);
        } else {
          setMask('');
          if (value === '_______') {
            searchRef.current.value = '';
          }
        }
      } else {
        setMask('');
      }
    }
  }

  async function handleSearch(e?: any) {
    if (e) {
      e.preventDefault();
    }

    try {
      const matricula = searchRef.current.value.replace('_', '');

      const targetOnList = target?.find((item) => item._id === matricula);

      if (targetOnList) {
        return;
      }

      setSearching(true);

      const result = await findPlayer(searchRef.current.value, true);

      const filteredResults = result.filter(
        (item: any) => item._id !== user?._id
      );

      if (filteredResults.length === 1) {
        addTarget(filteredResults[0]);
      } else {
        setSearchResults(filteredResults);
      }

      setSearching(false);
    } catch (e) {
      setSearching(false);
    }
  }

  async function handleSubmit() {
    try {
      if (loading || !message) return;

      setLoading(true);

      // window.postMessage({ type: 'achievement', value: { name: 'asdasdasd' } }, '*');

      let team: any = [];

      if (target) {
        team =
          target.map((item) => {
            return { _id: item._id };
          }) || [];
        // team.push({ _id: user?._id });
      }

      team = validateDistribution(team);

      if (params.id && params.id !== 'cadastrar') {
        // console.log('UPDATE', updatedTask);

        if (
          currentTask.status === 'rejeitado' &&
          !!currentTask?.feedback?.length
        ) {
          deleteMissionFeedback(currentTask._id);
        }

        await updateTask({
          ...currentTask,
          team,
          title,
          start: { $date: new Date(periodStart).getTime() },
          end: { $date: new Date(periodEnd).getTime() },
          nec,
          description: message,
          status: playerInfo.cod_etapa > 0 ? currentTask.status : 'aguardando',
        });
      } else {
        const serverTime = await getServerTime();

        if (
          !currentCycle?.status ||
          currentCycle.status !== 'open' ||
          serverTime > currentCycle.date_finish ||
          serverTime < currentCycle.date_start
        ) {
          return toast.error(
            `Não há fase disponível para envio de realizações.`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }

        await submitTask({
          owner: user?._id,
          team,
          start: { $date: new Date(periodStart).getTime() },
          end: { $date: new Date(periodEnd).getTime() },
          nec,
          title,
          description: message,
          status: 'aguardando',
          original_manager: playerInfo.gestor,
        });
      }

      setLoading(false);
      setSubmitted(true);

      refreshUser();

      // if (log.action && log.action.attributes.status && log.action.attributes.status !== 'OK') {
      //   console.log(log.action.attributes.status);
      //   switch (log.action.attributes.status) {
      //     case 'sem fundos':
      //       setSubmitError('Você não possui saldo para esta Moeda BB');
      //       break;
      //     case 'auto reconhecimento':
      //       setSubmitError('Você não pode enviar Moedas BB para si mesmo');
      //       break;
      //     default:
      //       setSubmitError(log.action.attributes.status);
      //       break;
      //   }
      // }
    } catch (e) {
      setLoading(false);
    }
  }

  function removeTarget(index: number) {
    if (target && target.length) {
      let newTargetList = [...target];
      newTargetList.splice(index, 1);
      setTarget(newTargetList);
    }
  }

  function addTarget(item: User) {
    const newTargetList = target || [];
    newTargetList.push(item);
    setTarget(newTargetList);
    setSearchResults([]);
    setSearching(false);
    if (searchRef && searchRef.current) {
      searchRef.current.value = '';
    }
  }

  function reset() {
    setTarget(undefined);
    // setBehavior(undefined);
    // setCoin(undefined);
    setSubmitted(false);
    setLoading(false);
    setSubmitError(undefined);
    setSearchResults([]);
    setSearching(false);
    setMessage(undefined);
    setTitle(undefined);
    setPeriodStart(undefined);
    setPeriodEnd(undefined);
    setNec(undefined);
    setStep(0);
    setTeamDistribution(undefined);
  }

  function handleCloseModal() {
    reset();
    navigate('../', { state: { direction: 'back' } });
  }

  // function hasEnoughCoins(coin_type: string) {
  //   try {
  //     if (!user) {
  //       return true;
  //     }
  //     return user.point_categories[coin_type] > 0;
  //   } catch (e) {
  //     return true;
  //   }
  // }

  function afterOpenModal() {}

  function validateInput(e: any) {
    if (e.target.value > 100) {
      e.target.value = 100;
    } else if (e.target.value < 0) {
      e.target.value = 0;
    } else if (e.target.value === '') {
      e.target.value = 0;
    }

    const teamMember = e.target.id.replace('team_player_', '');

    setTeamDistribution({ ...teamDistribution, [teamMember]: e.target.value });

    // validateDistribution();
  }

  const isValidDistribution: boolean = useMemo((): boolean => {
    if (!target?.length || !Object.keys(teamDistribution).length) return false;

    for (let index = 0; index < target.length; index++) {
      const teamItem = teamDistribution[target[index]._id];

      if (!teamItem || isNaN(Number(teamItem)) || Number(teamItem) <= 0) {
        return false;
      }
    }

    return true;
  }, [target, teamDistribution]);

  function validateDistribution(team: any) {
    if (team) {
      let valid = true;
      let updatedTeam = [...team];
      const distribution = updatedTeam.map((item: any, index: number) => {
        try {
          const teamInput: any = teamDistribution[item._id] || 0;
          // console.log(teamInput);
          if (isNaN(teamInput) || teamInput > 100 || teamInput <= 0) {
            valid = false;
          }
          return { ...item, percent: parseInt(teamInput) };
        } catch (e) {
          console.log(e);
          valid = false;
          return { ...item };
        }
      });

      if (valid) {
        return distribution;
      } else {
        return team;
      }
    } else {
      return [];
    }
  }

  function getUserDistribution(_id: string) {
    try {
      return teamDistribution[_id];
    } catch (e) {
      return 0;
    }
  }

  function getPositionLabel() {
    try {
      return (
        <div className="p-1 text-left text-white text-sm font-thin">
          <p>
            NECs são necessidades de tecnologia apresentadas pelas áreas de
            negócio ou DITEC, onde serão criadas soluções de valor para o
            cliente. Essa informação é obrigatória apenas para as realizações
            cadastradas pelas equipes de desenvolvimento da DITEC.
          </p>
        </div>
      );
    } catch (e) {
      return `---`;
    }
  }

  useEffect(() => {
    async function loadData() {
      const userInfo = await getUserInfo();
      const result = await getTaskById(params.id);

      if (result && userInfo) {
        setCurrentTask(result);

        const isOwnerOnTeam: any = result.team.find(
          (item: any) => item._id === user?._id
        );

        if (!isOwnerOnTeam && userInfo.cad_missao > 0) {
          result.target.push({
            _id: user?._id,
            name: user?.name,
            extra: user?.extra,
          });
          result.team.push({
            _id: user?._id,
          });
        }

        const currentTeam = result.target.sort((a: any, b: any) =>
          a._id === user?._id ? -1 : 1
        ); // = result.target.filter((item:any)=>item._id !== user?._id);
        const currentDistribution = result.team.reduce(
          (value: any, item: any) => {
            value[item._id] = item.percent;
            return value;
          },
          {}
        );

        setTarget(currentTeam);
        setTeamDistribution(currentDistribution);
        setMessage(result.description);
        setTitle(result.title);
        setNec(result.nec || undefined);

        try {
          setPeriodStart(
            format(utcToZonedTime(result.start, 'GMT'), 'yyyy-MM-dd')
          );
          setPeriodEnd(format(utcToZonedTime(result.end, 'GMT'), 'yyyy-MM-dd'));
        } catch (e) {
          console.log(e);
        }

        const date = new Date(result.created);

        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

        setMaxDate(format(lastDay, 'yyyy-MM-dd'));
        setMinDate(format(firstDay, 'yyyy-MM-dd'));
      }

      setLoading(false);
    }

    async function loadUserBySearch() {
      await getUserInfo();

      const currentTime = await getServerTime();

      const date = new Date(currentTime);

      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

      setMaxDate(format(lastDay, 'yyyy-MM-dd'));
      setMinDate(format(firstDay, 'yyyy-MM-dd'));

      const result = await findPlayer(user?._id);

      setTarget(result);
    }

    if (user) {
      if (params && params.id && params.id !== 'cadastrar') {
        loadData();
      } else {
        loadUserBySearch();
      }
    }
  }, [getUserInfo, params, user]);

  const periodEndIsValid: boolean = useMemo((): boolean => {
    if (
      !!periodEnd?.length &&
      !!minDate?.length &&
      !!maxDate?.length &&
      new Date(periodEnd) >= new Date(minDate) &&
      new Date(periodEnd) <= new Date(maxDate)
    ) {
      return true;
    }

    return false;
  }, [periodEnd, minDate, maxDate]);

  const buttonContinueIsDisabled = () => {
    return (
      (step === 0 && (!target?.length || !isValidDistribution)) ||
      (step === 1 &&
        (!title?.length ||
          !periodStart?.length ||
          !periodEnd?.length ||
          !periodEndIsValid ||
          new Date(periodEnd) < new Date(periodStart)))
    );
  };

  useEffect(() => {
    async function playerCanView() {
      const serverTime = await getServerTime();

      if (
        !currentCycle?.status ||
        currentCycle.status !== 'open' ||
        serverTime > currentCycle.date_finish ||
        serverTime < currentCycle.date_start
      ) {
        toast.error(`Não há fase disponível para envio de realizações.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return navigate('../');
      }
    }

    if (!!currentCycle && params && params.id && params.id === 'cadastrar') {
      playerCanView();
    }
  }, [currentCycle, navigate, params]);

  if (
    params &&
    params.id &&
    params.id === 'cadastrar' &&
    playerInfo?.cad_missao <= 0
  ) {
    return null;
  }

  return (
    <ReactModal
      key={'modal-create-mission'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Modal Entrega"
      portalClassName="modal-create-mission"
      overlayClassName="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
      contentElement={() => (
        <>
          {!submitted && (
            <Box className="relative bg-oxford-blue border-electric-blue py-8 px-5 lg:px-16 flex flex-col w-[90%] lg:max-w-screen-md justify-center items-center border-opacity-100 bg-opacity-100">
              <button
                className="absolute -top-5 -right-5 button-close"
                onClick={() => {
                  if (searchResults.length > 0) {
                    reset();
                  } else {
                    setIsOpen(false);
                  }
                }}
              >
                <IoMdCloseCircle size={48} className="text-slate-blue" />
              </button>
              <div className="mx-auto relative container overflow-y-auto overflow-x-hidden max-h-[85vh]">
                <div className="block mb-8">
                  <h1 className="text-lg uppercase block text-center text-yellow">
                    {params.id && params.id !== 'cadastrar'
                      ? 'Editar'
                      : 'Cadastrar'}{' '}
                    Realização Destaque
                  </h1>
                </div>

                {!loading && !submitted && (
                  <div className="space-y-8">
                    <div className="relative flex flex-row items-center justify-around">
                      <div className="absolute z-0 w-full h-[1px] top-0 bottom-0 m-auto border-b border-white opacity-20"></div>
                      <div
                        className={`${
                          step === 0
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        1
                      </div>
                      <div
                        className={`${
                          step === 1
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        2
                      </div>
                      <div
                        className={`${
                          step === 2
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        3
                      </div>
                    </div>

                    {step === 0 && (
                      <div className="space-y-6">
                        <div className="space-x-4 flex flex-row items-center justify-between w-full">
                          <div className="relative flex-1">
                            <span className="text-alice-blue mb-4 block text-base font-thin">
                              Inclua a equipe que trabalhou com você para a
                              conclusão desta realização, especificando o
                              percentual estimado de atuação de cada uma delas.
                            </span>

                            {(!target?.length || target?.length < 12) && (
                              <>
                                <div className="space-x-4 flex flex-row items-center justify-between">
                                  <form
                                    onSubmit={handleSearch}
                                    className="pl-1 flex-1"
                                  >
                                    <InputMask
                                      placeholder="Digite o nome ou chave do funcionário"
                                      alwaysShowMask={false}
                                      mask={mask}
                                      ref={(ref: any) => {
                                        searchRef.current = ref;
                                      }}
                                      onChange={(e) => handleChange()}
                                      inputMode="search"
                                      style={{ fontSize: 16 }}
                                      className="w-full bg-maastricht-blue text-white text-sm font-light flex-1 rounded-md py-2 px-4"
                                    />
                                  </form>

                                  <TbSearch
                                    size={28}
                                    className="cursor-pointer hover:ring-2 mr-1 rounded-full"
                                    onClick={() => handleSearch()}
                                  />
                                </div>

                                <p className="block mt-2 text-sm text-white font-thin">
                                  *Você pode adicionar até 12 pessoas nesta
                                  realização. Insira apenas colegas que
                                  efetivamente participaram da entrega.
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        {target && target?.length > 0 && !searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-2 lg:px-8 rounded-3xl">
                            {target.map((item: User, index: number) => (
                              <div
                                key={item._id}
                                className={`w-full flex flex-col  lg:flex-row lg:items-center justify-between ${
                                  index + 1 < target.length
                                    ? 'border-b border-prussian-blue pb-4 mb-4 '
                                    : ''
                                }`}
                              >
                                <div className="flex flex-1 relative">
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar
                                      data={{
                                        image: item.image,
                                        ...item.extra,
                                      }}
                                    />
                                  </div>

                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin">
                                      <strong className="text-base font-semibold truncate w-[35vw] lg:w-auto block">
                                        {item.name}
                                      </strong>{' '}
                                      ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria ||
                                    !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && (
                                          <span className="text-electric-blue uppercase">
                                            {item.extra?.diretoria}
                                          </span>
                                        )}
                                        {!!item.extra?.diretoria &&
                                          !!item.extra?.funcao &&
                                          ' - '}
                                        {item.extra?.funcao
                                          ? `${item.extra?.funcao}`
                                          : ''}
                                      </span>
                                    ) : null}
                                  </p>

                                  <RiCloseLine
                                    size={44}
                                    className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue absolute right-0  lg:hidden"
                                    onClick={() => removeTarget(index)}
                                  />
                                </div>

                                <div className="ml-[65px] mt-3 lg:ml-0 lg:mt-0 w-24 lg:w-20 flex flex-row items-center text-yellow space-x-1">
                                  <input
                                    id={`team_player_${item._id}`}
                                    type="number"
                                    onChange={validateInput}
                                    placeholder="0"
                                    defaultValue={getUserDistribution(item._id)}
                                    maxLength={3}
                                    className={`appearance-none bg-maastricht-blue rounded-md min-w-[54px] text-base text-alice-blue py-1 px-0 text-center ${
                                      !getUserDistribution(item._id) ||
                                      Number(getUserDistribution(item._id)) <= 0
                                        ? 'border border-[#701e1e]'
                                        : ''
                                    }`}
                                  />
                                  <b className="text-yellow text-base font-semibold">
                                    %
                                  </b>
                                </div>

                                <RiCloseLine
                                  size={44}
                                  className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue hidden lg:block"
                                  onClick={() => removeTarget(index)}
                                />
                              </div>
                            ))}
                          </div>
                        )}

                        {searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 p-8 rounded-3xl">
                            <p className="animate-pulse text-yellow">
                              Recuperando informações...
                            </p>
                          </div>
                        )}

                        {!!searchResults && searchResults.length > 0 && (
                          <>
                            <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-8 rounded-3xl h-[30vh] lg:h-auto overflow-y-auto lg:overflow-y-visible">
                              {searchResults.map((item, index) => (
                                <div
                                  key={item._id}
                                  onClick={() => addTarget(item)}
                                  className={`cursor-pointer hover:opacity-75 w-full flex flex-row items-center justify-between border-b border-prussian-blue pb-4 mb-4`}
                                >
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar
                                      data={{
                                        image: item.image,
                                        ...item.extra,
                                      }}
                                    />
                                  </div>
                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin">
                                      <strong className="text-base font-semibold">
                                        {item.name}
                                      </strong>{' '}
                                      ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria ||
                                    !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && (
                                          <span className="text-electric-blue uppercase">
                                            {item.extra?.diretoria}
                                          </span>
                                        )}
                                        {!!item.extra?.diretoria &&
                                          !!item.extra?.funcao &&
                                          ' - '}
                                        {item.extra?.funcao
                                          ? `${item.extra?.funcao}`
                                          : ''}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              ))}

                              <div className="text-center pt-2 text-white font-base font-light">
                                Não encontrou quem procura? Experimente buscar
                                por nome e sobrenome ou matrícula.
                              </div>
                            </div>
                          </>
                        )}

                        {target &&
                          target?.length > 0 &&
                          buttonContinueIsDisabled() && (
                            <p className="block font-light text-yellow">
                              Para continuar, preencha o percentual estimado de
                              atuação de cada membro da equipe.
                            </p>
                          )}
                      </div>
                    )}

                    {step === 1 && (
                      <>
                        <div className="border-2 border-electric-blue rounded-2xl p-8 flex flex-col w-full space-y-4 lg:space-y-6">
                          <div className="text-white text-lg lg:text-lg block leading-none font-semibold">
                            Informações da Realização Destaque
                          </div>

                          <div>
                            <label className="text-white font-thin text-xs leading-none mb-2 block">
                              Nome da realização
                            </label>
                            <input
                              placeholder="Dê um nome à sua realização"
                              defaultValue={title}
                              ref={(ref) => (titleRef.current = ref)}
                              onChange={(e) => setTitle(e.target.value)}
                              className="w-full bg-maastricht-blue text-white py-2 px-4 rounded-md leading-none text-sm font-light"
                            />
                          </div>

                          <div className="w-full flex flex-col lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
                            <div className="lg:w-1/2">
                              <label className="text-white font-thin text-xs leading-none mb-2 block">
                                Início da entrega
                              </label>
                              <input
                                type="date"
                                defaultValue={periodStart}
                                ref={(ref) => (periodStartRef.current = ref)}
                                onChange={(e) =>
                                  setPeriodStart(
                                    format(
                                      utcToZonedTime(e.target.value, 'GMT'),
                                      'yyyy-MM-dd'
                                    )
                                  )
                                }
                                className="w-full bg-maastricht-blue text-white text-sm font-light py-2 px-4 rounded-md leading-none"
                              />
                            </div>

                            <div className="lg:w-1/2">
                              <label className="text-white font-thin text-xs leading-none mb-2 block">
                                Conclusão da Entrega
                              </label>
                              <input
                                // min={minDate}
                                // max={maxDate}
                                defaultValue={periodEnd}
                                type="date"
                                ref={(ref) => (periodEndRef.current = ref)}
                                onChange={(e) =>
                                  setPeriodEnd(
                                    format(
                                      utcToZonedTime(e.target.value, 'GMT'),
                                      'yyyy-MM-dd'
                                    )
                                  )
                                }
                                className="w-full bg-maastricht-blue text-white py-2 px-4 rounded-md leading-none text-sm font-light"
                              />
                            </div>
                          </div>

                          <div>
                            <label className="text-white font-thin text-xs leading-none mb-2 flex items-center">
                              Número da NEC{' '}
                              <span className="text-electric-blue mx-1">
                                (opcional Áreas de Tecnologia)
                              </span>
                              <Tippy
                                className="drop-shadow-lg"
                                allowHTML={true}
                                content={getPositionLabel()}
                              >
                                <span className="block">
                                  <BiInfoCircle className="text-white text-base -my-1 block" />
                                </span>
                              </Tippy>
                            </label>
                            <input
                              placeholder="123456789"
                              defaultValue={nec}
                              ref={(ref) => (necRef.current = ref)}
                              onChange={(e) => setNec(e.target.value)}
                              className="w-full bg-maastricht-blue text-white py-2 px-4 rounded-md leading-none text-sm font-light"
                            />
                          </div>
                        </div>

                        {!!periodEnd?.length && !periodEndIsValid ? (
                          <p className="block font-light text-yellow">
                            A data de Conclusão da entrega deve conter somente
                            dias do mês atual. Altere para prosseguir.
                          </p>
                        ) : null}
                      </>
                    )}

                    {step === 2 && (
                      <div className="border-2 border-electric-blue rounded-2xl p-8 flex flex-col w-full space-y-2">
                        <div className="text-white text-sm leading-none block font-thin">
                          Detalhes da Realização Destaque
                        </div>

                        <textarea
                          defaultValue={message}
                          ref={(ref) => (messageRef.current = ref)}
                          onChange={(e: any) => setMessage(e.target.value)}
                          className="h-28 w-full bg-maastricht-blue text-white py-2 px-4 rounded-md text-sm font-light"
                        ></textarea>

                        <p className="block mt-2 text-sm text-white font-thin">
                          Na descrição, não se esqueça de demonstrar clareza e
                          objetividade dos resultados e benefícios alcançados
                          por meio da sua realização destaque e fique bem na
                          foto!
                        </p>
                      </div>
                    )}

                    <div className="flex lg:block justify-center">
                      <div className="p-2 px-45 flex flex-row space-x-8 items-center justify-center">
                        {step > 0 && (
                          <button
                            onClick={() => setStep(step - 1)}
                            className={`eading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              Voltar
                            </p>
                          </button>
                        )}
                        {step < 2 && (
                          <button
                            onClick={() =>
                              !buttonContinueIsDisabled()
                                ? setStep(step + 1)
                                : null
                            }
                            disabled={buttonContinueIsDisabled()}
                            className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              Continuar
                            </p>
                          </button>
                        )}
                        {step === 2 && (
                          <button
                            disabled={!message}
                            onClick={() => handleSubmit()}
                            className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              {params.id && params.id !== 'cadastrar'
                                ? 'Atualizar'
                                : 'Enviar!'}
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {loading && !submitted && (
                  <div className="space-y-4 mt-12">
                    <div className="flex flex-row items-center justify-center text-xl text-yellow animate-pulse">
                      <div></div>
                      Enviando Realização Destaque...
                    </div>
                  </div>
                )}
              </div>
            </Box>
          )}

          {submitted && (
            <div className="border-2 rounded-3xl p-3 relative bg-oxford-blue border-electric-blue p-8 flex flex-col w-[324px] lg:max-w-screen-md justify-center items-center">
              <button
                className="absolute -top-5 -right-5 button-close"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <IoMdCloseCircle size={48} className="text-slate-blue" />
              </button>

              <div className="flex flex-col items-center justify-center">
                {!submitError && (
                  <>
                    <div className="flex items-center justify-center mb-6">
                      <img
                        src={FeedbackImg}
                        alt="Enviado!"
                        className="w-[173px] block"
                      />
                    </div>
                    <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">
                      Mandou bem!
                    </h1>
                    <p className="text-sm font-white font-light text-center block">
                      Sua Realização Destaque foi{' '}
                      {params.id && params.id !== 'cadastrar'
                        ? 'atualizada'
                        : 'cadastrada'}
                      . Agora você poderá acompanhar o processo de validação da
                      entrega que será realizado pelos seus superiores.
                    </p>
                  </>
                )}

                {submitError && (
                  <>
                    <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">
                      Não deu pra enviar essa Realização Destaque!
                    </h1>
                    <p className="text-sm font-white font-light text-center block mb-8">
                      {submitError}
                    </p>
                    <button
                      onClick={() => reset()}
                      className={`leading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}
                    >
                      Tentar novamente
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    />
  );
}
