/* eslint-disable react-hooks/exhaustive-deps */
import { PointReviewItem } from 'components/point-review-item';
import { RewardReviewItem } from 'components/reward-review-item';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Box from 'components/ui/box';
import { useEffect, useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import {
  getMissaoGestorByPeriod,
  getMissaoEquipeByTime,
} from 'services/achievement.service';
import { getKPIHistoryByTime, getPlayerInfo } from 'services/dashboard.service';
import { getUserProfile } from 'services/profile.service';
import Avatar from 'funifier-nice-avatar';

interface GaleriaConhecimentoDetailProps {
  id?: string;
  tab: number;
  onClose: () => void;
}
export function GaleriaConhecimentoDetail({
  id,
  tab,
  onClose,
}: GaleriaConhecimentoDetailProps) {
  const [user, setUser] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();

  const [list, setList] = useState<unknown[]>();
  const [missionList, setMissionList] = useState<unknown[]>();

  const [loadingList, setLoadingList] = useState(true);
  const [loadingMissionList, setLoadingMissionList] = useState(true);

  const time = {
    start: tab === 1 ? '2022-10-17T03:00:00.000Z' : '2023-01-01T03:00:00.000Z',
    end: tab === 1 ? '2022-12-31T03:00:00.000Z' : '2023-12-22T03:00:00.000Z',
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    async function loadPlayerInfo() {
      if (!id) return;

      const userProfile = await getUserProfile(id);
      const playerInfo = await getPlayerInfo(id);
      setUserInfo(playerInfo);
      setUser(userProfile);

      const isGestor =
        playerInfo.tipo_ro === 'GERENCIAL' || playerInfo.funcao === 'DIRETOR';

      if (isGestor) {
        const dataGestor = await getMissaoGestorByPeriod(
          id,
          '2022-10-17T03:00:00.000Z',
          '2022-12-31T03:00:00.000Z'
        );
        const dataEquipe = await getMissaoEquipeByTime(
          id,
          '2022-10-17T03:00:00.000Z',
          '2022-12-31T03:00:00.000Z'
        );
        setMissionList([...dataGestor, ...dataEquipe]);
      } else {
        const data = await getMissaoEquipeByTime(
          id,
          '2022-10-17T03:00:00.000Z',
          '2022-12-31T03:00:00.000Z'
        );
        setMissionList(data);
      }

      setLoadingMissionList(false);

      loadReconhecimentos();
    }

    async function loadReconhecimentos() {
      const result = await getKPIHistoryByTime(
        id,
        time.start,
        time.end
      );
      setList(result);
      setLoadingList(false);
    }

    loadPlayerInfo();
    // loadReconhecimentos();
  }, [id]);

  return (
    <div className="mx-auto container p-3 lg:p-10">
      <div className="flex flex-row items-center space-x-3 lg:space-x-2 mb-3 lg:mb-10">
        <div className="cursor-pointer" onClick={() => onClose()}>
          <AiFillLeftCircle size={48} className="text-purple" />
        </div>
        <h1 className="text-lg lg:text-3xl py-1 px-6">
          Reconhecimentos de {user?.name}
        </h1>
      </div>

      {userInfo && (
        <div className="flex flex-row items-center space-x-3 mb-5">
          <div className="w-32 h-32 md:w-44 md:h-44 bg-purple flex items-center justify-center rounded-full overflow-hidden text-3xl md:text-6xl">
            {!!user.extra.avatar_type &&
              (user.image ? (
                <img
                  src={user.image.medium.url}
                  alt={user.name}
                  className="w-full h-full"
                />
              ) : (
                user.name[0]
              ))}
            {!user.extra.avatar_type &&
              (!!user.extra.avatar ? (
                <Avatar className="w-full h-full" {...user.extra.avatar} />
              ) : (
                user.name[0]
              ))}
          </div>
          <div className="space-y-3">
            <div className="text-lg md:text-2xl text-white text-center font-bold">
              {user?.name} <span className="font-thin">({user?._id})</span>
            </div>
            {/* <p className='text-xl font-thin'>Nível: <b className='text-electric-blue font-bold'>Conectado</b></p> */}
            {userInfo && (
              <div className="md:text-xl font-thin">
                <span>{userInfo?.grupo?.nome}</span>{' '}
                <span>({userInfo?.grupo?.total} funci)</span>{' '}
                <span className="font-bold text-md p-1 px-4 rounded-2xl bg-maastricht-blue text-electric-blue">
                  {userInfo?.ro}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-full flex flex-col space-y-5">
        {tab === 1 && (
          <Box>
            <div className="space-y-5 lg:p-3">
              <h3 className="text-2xl mb-3">Realização Destaque</h3>

              {loadingMissionList ? (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              ) : null}

              {!loadingMissionList && (
                <div className="space-y-2">
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                    {missionList?.map((item: any) => (
                      <PointReviewItem data={item} key={item._id} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Box>
        )}

        <Box>
          <div className="space-y-5 lg:p-3">
            <h3 className="text-2xl mb-3">
              Reconhecimentos recebidos{' '}
              <b className="text-yellow">{list ? `(${list?.length})` : null}</b>
            </h3>

            {loadingList ? (
              <div className="space-y-2">
                <ArticlePlaceholder />
                <ArticlePlaceholder />
              </div>
            ) : null}

            {!loadingList ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {list?.map((item: any) => (
                  <RewardReviewItem data={item} key={item._id} />
                ))}
              </div>
            ) : null}
          </div>
        </Box>
      </div>
    </div>
  );
}
