import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';

import Logo from '../../assets/img/logo-festa-junina.svg';
import { Link, useNavigate } from 'react-router-dom';

import { AzureProfile } from 'sso/azure/ui/AzureProfile';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'sso/azure/authConfig';
import { getAzureToken } from 'sso/azure/azure-api';
import Button from 'components/ui/button';
import { BiDesktop, BiMobileAlt } from 'react-icons/bi';

export function Login() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const { loginWithToken, login } = useContext(AuthContext);
  const [useSSO, setUseSSO] = useState(false);

  // function handleLogin() {
  //   window.location.href = 'https://bb.funifier.com';
  // }

  // function handleLoginExterno() {
  //   window.location.href = 'https://bb-externo.funifier.com';
  // }

  function loginSSO() {
    setLoading(true);
    setUseSSO(true);
    setInvalidUser(false);
  }

  async function handleLoginSSO(response: any) {
    // console.log('handleLoginSSO', response);

    try {
      const password = await getAzureToken();
      const username = response.userPrincipalName;

      if (username && password) {
        await login({ username, password });
        navigate('/');
      }
    } catch (e) {
      setLoading(false);
      setInvalidUser(true);
      setUseSSO(false);
    }
  }

  useEffect(() => {
    async function doLogin(username: string, password: string) {
      try {
        await login({ username, password });
        // navigate('/');
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    async function doLoginWithToken(token: string) {
      try {
        await loginWithToken(token);
        // navigate('/');
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const agent = params.get('agent');
    const secret = params.get('secret');

    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else {
      // setUseSSO(true);
      setInvalidUser(true);
    }
  }, [loginWithToken, login]);

  return (
    <div className="absolute left-0 right-0 min-h-full m-auto px-5 md:w-1/2 max-w-[600px] flex flex-col items-center justify-center">
      <div>
        <img
          src={Logo}
          className="pointer-events-none h-36 lg:h-48 relative"
          alt="Joga Junto BB"
        />
      </div>

      <div className="w-full p-3 mt-2 lg:mt-10 rounded-full text-center text-yellow font-bold text-2xl">
        Juntos fazemos muito mais!
      </div>

      <div className="mb-2 w-full border-t border-white"></div>

      <p className="text-center text-bold text-xl">
        A participação no #JogaJuntoBB é opcional.
      </p>

      <p className="text-center text-lg">
        Para participar leia o{' '}
        <Link to="/regulamento" className="text-yellow">
          regulamento
        </Link>{' '}
        e manifeste sua ciência e concordância com os termos e condições da
        ação.
      </p>

      {loading && !useSSO && !invalidUser && (
        <div className="w-full border text-blue-dark bg-yellow p-3 mt-5 rounded-xl text-center text-gray font-bold text-xl">
          CARREGANDO...
        </div>
      )}

      {!loading && invalidUser && (
        <>
          {/* <p className="text-white font-bold text-yellow my-3 xl:mt-5 xl:text-lg">
            Clique para entrar:
          </p> */}

          <div className="mt-6 w-full flex flex-col space-y-3 xl:flex-row xl:items-center xl:space-y-0 xl:space-x-3">
            <Button
              color="clear"
              className="w-full text-xl p-2 bg-yellow text-blue-dark"
              onClick={() => {
                instance.loginPopup(loginRequest);

                setTimeout(() => {
                  loginSSO();
                }, 200);
              }}
            >
              <div className="flex flex-row items-center justify-center space-x-2">
                <BiDesktop />
                <span>Clique para entrar</span>
              </div>
            </Button>
          </div>
        </>
      )}

      {useSSO ? <AzureProfile onAuthenticate={handleLoginSSO} /> : null}
    </div>
  );
}
