import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { actionLog } from 'services/actionlog.service';
import { RegulamentoContent } from 'components/regulamento-content';
import { getStorageItem, removeStorageItem } from 'utils/storage';
import { checkInvite } from 'services/dashboard.service';

// interface RegulamentoModalProps {
//   on
// }

export function RegulamentoModal(props: any) {
  // const modalID = "achievement";

  // const modalID = 'help-pontos';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { signOut, refreshUser, user } = useAuth();

  // const { openModal, closeModal } = useContext(ModalContext);

  const acceptRef = useRef<any>();

  const [accept, setAccept] = useState(false);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  function handleAccept(e: any) {
    setAccept(e.target.checked);
  }

  async function handleSubmit() {
    if (!user) return;

    await actionLog('me', 'accept_rules');

    const sender = getStorageItem('jogajuntobb_invite_code');

    if (typeof sender === 'string') {
      const hasInvite = await checkInvite(user._id, sender);

      if (hasInvite) {
        await actionLog('me', 'accept_invite', { sender });
      }

      removeStorageItem('jogajuntobb_invite_code');
    }

    refreshUser();
  }

  useEffect(() => {
    if (user) {
      try {
        setIsOpen(!user.extra.accept);
      } catch (e) {
        setIsOpen(false);
      }
    }
  }, [user]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="max-w-[900px] bg-blue-dark text-white rounded-xl p-4 lg:p-10 flex flex-col items-center space-y-2 flex-none mx-5">
              <div className="mb-3 w-[105%]">
                <h1 className="text-center text-yellow text-2xl mb-2">
                  Regulamento
                </h1>

                <div className="space-y-5 lg:p-3 h-[35vh] overflow-y-auto mb-4">
                  <RegulamentoContent modal />
                </div>

                <div className="border-2 border-yellow rounded-lg p-3">
                  <label className="cursor-pointer text-center">
                    <input
                      id="accept"
                      type="checkbox"
                      name="accept"
                      className="mr-1"
                      onChange={(e) => handleAccept(e)}
                      ref={(ref) => {
                        acceptRef.current = ref;
                      }}
                    />{' '}
                    Concordo com os termos e condições do regulamento
                  </label>
                </div>
              </div>

              <div className="flex flex-row space-x-4">
                <Button className="leading-none p-2" onClick={() => signOut()}>
                  Sair do #JogaJuntoBB
                </Button>
                <Button
                  color="clear"
                  className="leading-none disabled:opacity-50 p-2  bg-yellow text-blue-dark"
                  disabled={!accept}
                  onClick={() => handleSubmit()}
                >
                  Confirmar participação
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
