import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { RouteList } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/fonts/index.scss';
import './App.scss';

import { ModalProvider } from 'context/ModalContext';
import ToastProvider from 'context/ToastContext';
import { WebsocketProvider } from 'context/WebsocketContext';
import HelpButton from 'components/help-button';
import { AchievementModal } from 'components/achievement-modal';
import { WebsocketNotificationProvider } from 'context/WebsocketNotificationContext';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => (
  <Router>
    {/* <MsalProvider instance={pca}> */}
    <AuthProvider>
      <WebsocketNotificationProvider>
        <WebsocketProvider>
          <ModalProvider>
            <RouteList msalInstance={pca} />
            <ToastProvider />
            <HelpButton />
            <AchievementModal />
          </ModalProvider>
        </WebsocketProvider>
      </WebsocketNotificationProvider>
    </AuthProvider>
    {/* </MsalProvider> */}
  </Router>
);

export default App;
