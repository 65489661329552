/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Avatar from 'funifier-nice-avatar';
import Box from 'components/ui/box';
import ReactModal from 'react-modal';
import { useEffect, useRef, useState } from 'react';
import { TbSearch } from 'react-icons/tb';
import InputMask from 'react-input-mask';
import { Editor } from '@tinymce/tinymce-react';
import { findPlayer } from 'services/profile.service';
import { User } from 'types/user';
import { RiCloseLine } from 'react-icons/ri';
import { IoMdCloseCircle } from 'react-icons/io';
import { useAuth } from 'context/AuthContext';
import {
  Colaboracao,
  FocoNoCliente,
  Inovacao,
  MandouBem,
  Resolutividade,
  Uau,
  Valeu,
} from 'components/icons';
import { actionLog } from 'services/actionlog.service';
import {
  GetBehaviorDescription,
  GetBehaviorLabel,
  GetMoedaDescription,
  GetMoedaLabel,
} from 'utils/strings';

import './style.scss';
import { UserAvatar } from 'components/user-avatar';

import FeedbackGeral from 'assets/img/comportamento/bg-feedback-moedas.png';
import FeedbackMandou from 'assets/img/comportamento/mandou.svg';
import FeedbackUau from 'assets/img/comportamento/uau.svg';
import FeedbackValeu from 'assets/img/comportamento/valeu.svg';
import { getServerTime } from 'services/dashboard.service';

const maskTemplate = 'F9999999';

export default function Reconhecer() {
  const navigate = useNavigate();
  const params = useParams();

  const { user, refreshUser, currentCycle } = useAuth();

  const searchRef = useRef<any>();
  const messageRef = useRef<any>();

  const [isOpen, setIsOpen] = useState(true);

  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [target, setTarget] = useState<User>();
  const [behavior, setBehavior] = useState<string>();
  const [coin, setCoin] = useState<string | undefined>();
  const [message, setMessage] = useState<string>();

  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [step, setStep] = useState(0);

  const [submitError, setSubmitError] = useState<string | undefined>();

  const [mask, setMask] = useState('');

  ReactModal.setAppElement('#modal');

  function handleChange() {
    if (searchRef.current) {
      const value = searchRef.current.value.toUpperCase();
      if (value.length > 1) {
        if (value[0].toUpperCase() === 'F' && !isNaN(value[1])) {
          setMask(maskTemplate);
        } else {
          setMask('');
          if (value === '_______') {
            searchRef.current.value = '';
          }
        }
      } else {
        setMask('');
      }
    }
  }

  async function handleSearch(e?: any) {
    if (e) {
      e.preventDefault();
    }
    try {
      const matricula = searchRef.current.value.replace('_', '');

      if (!matricula || matricula === user?._id) {
        return;
      }

      setSearching(true);
      const result = await findPlayer(searchRef.current.value);
      const filteredResults = result.filter(
        (item: any) => item._id !== user?._id
      );
      if (filteredResults.length === 1) {
        setTarget(filteredResults[0]);
      } else {
        setSearchResults(filteredResults);
      }
      setSearching(false);
    } catch (e) {
      setSearching(false);
    }
  }

  async function handleSubmit() {
    try {
      if (loading || !target || !behavior || !coin || !message) return;

      const serverTime = await getServerTime();

      if (
        !currentCycle?.status ||
        currentCycle.status !== 'open' ||
        serverTime > currentCycle.date_finish ||
        serverTime < currentCycle.date_start
      ) {
        return toast.error(
          `Não há fase disponível para envio de reconhecimentos.`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      setLoading(true);

      // window.postMessage({ type: 'achievement', value: { name: 'asdasdasd' } }, '*');

      const log = await actionLog('me', 'reward_behavior', {
        target: target?._id,
        behavior,
        coin,
        message,
      });

      setLoading(false);
      setSubmitted(true);

      refreshUser();

      if (
        log.action &&
        log.action.attributes.status &&
        log.action.attributes.status !== 'OK'
      ) {
        // console.log(log.action.attributes.status);
        switch (log.action.attributes.status) {
          case 'sem fundos':
            setSubmitError('Você não possui saldo para esta Moeda BB.');
            break;
          case 'auto reconhecimento':
            setSubmitError('Você não pode enviar Moedas BB para si mesmo.');
            break;
          default:
            setSubmitError(log.action.attributes.status);
            break;
        }
      }
    } catch (e) {
      setLoading(false);
      setSubmitted(true);
      refreshUser();
      setSubmitError('Por favor, tente novamente em alguns minutos.');
    }
  }

  function reset() {
    setTarget(undefined);
    setBehavior(undefined);
    setCoin(undefined);
    setSubmitted(false);
    setLoading(false);
    setSubmitError(undefined);
    setSearchResults([]);
    setSearching(false);
    setMessage(undefined);
    setStep(0);
  }

  function handleCloseModal() {
    reset();
    navigate('../');
  }

  const getImgFeedback = (_coin: string) => {
    const imgs: any = {
      mandou: FeedbackMandou,
      uau: FeedbackUau,
      valeu: FeedbackValeu,
      geral: FeedbackGeral,
    };

    return imgs[_coin || 'geral'];
  };

  function hasEnoughCoins(coin_type: string) {
    try {
      if (!user) {
        return true;
      }

      if (
        !user?.point_categories?.point_behavior ||
        user?.point_categories?.point_behavior < 10
      ) {
        return false;
      }

      switch (coin_type) {
        case 'uau':
          if (user?.point_categories?.point_behavior - 50 >= 0) {
            return true;
          } else {
            return false;
          }
        case 'mandou':
          if (user?.point_categories?.point_behavior - 30 >= 0) {
            return true;
          } else {
            return false;
          }
        case 'valeu':
          if (user?.point_categories?.point_behavior - 10 >= 0) {
            return true;
          } else {
            return false;
          }
        default:
          return true;
      }
    } catch (e) {
      return true;
    }
  }

  function afterOpenModal() {}

  useEffect(() => {
    async function playerCanView() {
      const serverTime = await getServerTime();

      if (
        !currentCycle?.status ||
        currentCycle.status !== 'open' ||
        serverTime > currentCycle.date_finish ||
        serverTime < currentCycle.date_start
      ) {
        toast.error(`Não há fase disponível para envio de reconhecimentos.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return navigate('/');
      }
    }

    if (!!currentCycle) {
      playerCanView();
    }
  }, [currentCycle, navigate]);

  useEffect(() => {
    setTimeout(() => {
      if (!searching && searchRef && searchRef.current && params && params.id) {
        searchRef.current.value = params.id;
        handleSearch();
      }
    }, 1000);
  }, [params]);

  return (
    <ReactModal
      key={'modal-reconhecer'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Modal Reconhecer"
      portalClassName="modal-reconhecer"
      overlayClassName="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center overflow-hidden"
      contentElement={() => (
        <>
          {/* <Tesouro id="uau" x={3} y={83} /> */}
          {!submitted && (
            <Box className="relative bg-oxford-blue border-electric-blue p-1 lg:p-8 flex flex-col w-[90%] lg:max-w-screen-md justify-center items-center border-opacity-100 bg-opacity-100">
              <button
                className="absolute -top-5 -right-5 button-close"
                onClick={() => {
                  if (searchResults.length > 0) {
                    reset();
                  } else {
                    setIsOpen(false);
                  }
                }}
              >
                <IoMdCloseCircle size={48} className="text-slate-blue" />
              </button>
              <div className="mx-auto relative lg:container overflow-y-auto max-h-[85vh]">
                <div className="block mb-8 mx-8">
                  <h1 className="text-xl mt-4 lg:mt-0 lg:text-lg block text-center text-yellow">
                    Reconheça quem #JogaJunto!
                  </h1>
                  {/* <div className="flex-1 space-y-2"> */}
                  {/* <p>É rapidinho, você escolhe uma pessoa, um comportamento e a diz o quanto ela te impactou!</p> */}
                  {/* </div> */}
                </div>

                {!loading && !submitted && (
                  <div className="space-y-8">
                    <div className="relative flex flex-row items-center justify-around mx-8">
                      <div className="absolute z-0 w-full h-[1px] top-0 bottom-0 m-auto border-b border-white opacity-20"></div>
                      <div
                        className={`${
                          step === 0
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        1
                      </div>
                      <div
                        className={`${
                          step === 1
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        2
                      </div>
                      <div
                        className={`${
                          step === 2
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        3
                      </div>
                      <div
                        className={`${
                          step === 3
                            ? 'bg-slate-blue'
                            : 'bg-prussian-blue text-white'
                        } text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}
                      >
                        4
                      </div>
                    </div>

                    {step === 0 && (
                      <div className="mx-8">
                        <div className="space-x-4 flex flex-row items-center justify-between">
                          {!target &&
                            searchResults.length === 0 &&
                            !searching && (
                              <>
                                <div className="relative flex-1">
                                  <span className="text-alice-blue mb-3 block whitespace-nowrap text-base font-thin">
                                    Quem você gostaria de reconhecer?
                                  </span>
                                  {/* <input type="text" placeholder='Digite a matrícula' ref={ref=>searchRef.current = ref} className="w-full bg-black bg-opacity-30 text-xl text-yellow flex-1 rounded-xl p-2" /> */}
                                  <div className="space-x-4 flex flex-row items-center justify-between">
                                    <form
                                      onSubmit={handleSearch}
                                      className="pl-1 -ml-1 flex-1"
                                    >
                                      <InputMask
                                        placeholder="Digite o nome ou chave do funcionário"
                                        alwaysShowMask={false}
                                        mask={mask}
                                        ref={(ref: any) => {
                                          searchRef.current = ref;
                                        }}
                                        onChange={(e) => handleChange()}
                                        inputMode="search"
                                        style={{ fontSize: 16 }}
                                        className="w-full bg-maastricht-blue text-sm text-white font-thin flex-1 rounded-md py-2 px-4"
                                      />
                                    </form>

                                    <TbSearch
                                      size={28}
                                      className="cursor-pointer hover:ring-2 mr-1 rounded-full"
                                      onClick={() => handleSearch()}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {searching && (
                            <>
                              <p className="animate-pulse text-white text-base font-thin p-3">
                                Recuperando informações...
                              </p>
                            </>
                          )}

                          {!!target && !searching && (
                            <div className="relative flex-1">
                              <span className="text-alice-blue mb-3 block whitespace-nowrap text-base font-thin">
                                Você está reconhecendo:
                              </span>
                              <div className="flex-1 w-full flex flex-row items-center justify-between bg-rich-black bg-opacity-80 py-4 lg:px-8 rounded-3xl">
                                <div className="w-[44px] h-[44px] ml-2">
                                  <UserAvatar
                                    data={{
                                      image: target.image,
                                      ...target.extra,
                                    }}
                                  />
                                </div>
                                <p className="flex-1 pl-3 pr-2">
                                  <span className="block text-base font-semibold">
                                    {target.extra?.nome_guerra}
                                  </span>
                                  <span className="block text-xs font-thin">
                                    {target.name} ({target._id})
                                  </span>
                                  <span className="block text-xs font-thin">
                                    <span className="text-electric-blue uppercase">
                                      {target.extra?.dependencia}
                                    </span>{' '}
                                    - {target.extra?.funcao}
                                  </span>
                                </p>

                                <RiCloseLine
                                  size={44}
                                  className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue"
                                  onClick={() => reset()}
                                />
                              </div>
                            </div>
                          )}

                          {!target &&
                            !!searchResults &&
                            searchResults.length > 0 && (
                              <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-8 rounded-3xl">
                                {searchResults.map((item, index) => (
                                  <div
                                    key={item._id}
                                    onClick={() => setTarget(item)}
                                    className={`cursor-pointer hover:opacity-75 w-full flex flex-row items-center justify-between border-b border-prussian-blue pb-4 mb-4`}
                                  >
                                    <div className="w-[44px] h-[44px] ml-2">
                                      <UserAvatar
                                        data={{
                                          image: item.image,
                                          ...item.extra,
                                        }}
                                      />
                                    </div>
                                    <p className="flex-1 pl-3 pr-2">
                                      <span className="block text-base font-semibold">
                                        {item.extra?.nome_guerra}
                                      </span>
                                      <span className="block text-xs font-thin">
                                        {item.name} ({item._id})
                                      </span>
                                      <span className="block text-xs font-thin">
                                        <span className="text-electric-blue uppercase">
                                          {item.extra?.dependencia}
                                        </span>{' '}
                                        - {item.extra?.funcao}
                                      </span>
                                    </p>
                                  </div>
                                ))}
                                <div className="text-center pt-2 text-white font-base font-light">
                                  Não encontrou quem procura? Experimente buscar
                                  por nome e sobrenome ou matrícula.
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                    {step === 1 && (
                      <div className="block w-full">
                        <div className="block font-thin text-white text-base leading-none mb-5 px-5 lg:px-0  lg:mx-8">
                          Por qual comportamento você vai reconhecer{' '}
                          <strong className="text-electric-blue uppercase">
                            {target?.extra?.nome_guerra
                              ? target?.extra?.nome_guerra
                              : target?.name?.split(' ')[0]}
                          </strong>
                          ?
                        </div>

                        <div
                          className={`${
                            behavior
                              ? 'grid-items-reconhecer has-item'
                              : 'grid-items-reconhecer'
                          } grid grid-cols-3 bg-oxford-blue-2 rounded-lg w-full p-4 lg:flex lg:flex-row items-center justify-between mt-5 text-xs lg:text-md`}
                        >
                          <div
                            onClick={() => setBehavior('colaboracao')}
                            className={`${
                              !behavior || behavior === 'colaboracao'
                                ? ''
                                : 'opacity-50'
                            } elem-1 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                          >
                            <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                              <Colaboracao size={'100%'} />
                            </div>
                            <p className="text-sm text-white m-0 p-0 font-thin">
                              Colaboração
                            </p>
                          </div>

                          <div
                            onClick={() => setBehavior('inovacao')}
                            className={`${
                              !behavior || behavior === 'inovacao'
                                ? ''
                                : 'opacity-50'
                            } elem-2 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                          >
                            <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                              <Inovacao size={'100%'} />
                            </div>
                            <p className="text-sm text-white m-0 p-0 font-thin">
                              Inovação
                            </p>
                          </div>

                          <div
                            onClick={() => setBehavior('foco')}
                            className={`${
                              !behavior || behavior === 'foco'
                                ? ''
                                : 'opacity-50'
                            } elem-3 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                          >
                            <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                              <FocoNoCliente size={'100%'} />
                            </div>
                            <p className="text-sm text-white m-0 p-0 font-thin">
                              Foco no cliente
                            </p>
                          </div>

                          <div
                            onClick={() => setBehavior('resolutividade')}
                            className={`${
                              !behavior || behavior === 'resolutividade'
                                ? ''
                                : 'opacity-50'
                            } elem-4 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                          >
                            <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                              <Resolutividade size={'100%'} />
                            </div>
                            <p className="text-sm text-white m-0 p-0 font-thin">
                              Protagonismo
                            </p>
                          </div>

                          {!!behavior && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: GetBehaviorDescription(behavior),
                              }}
                              className="text-elem font-thin text-xs text-electric-blue block leading-tight"
                            ></div>
                          )}
                        </div>

                        <div className="bg-oxford-blue-2 rounded-lg w-full pb-4 lg:hidden">
                          <div
                            className={`grid grid-cols-2 lg:hidden gap-4  p-4 lg:flex lg:flex-row items-center justify-between mt-5 text-xs lg:text-md`}
                          >
                            <div
                              onClick={() => setBehavior('colaboracao')}
                              className={`${
                                !behavior || behavior === 'colaboracao'
                                  ? ''
                                  : 'opacity-50'
                              } elem-1 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                            >
                              <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                                <Colaboracao size={'100%'} />
                              </div>
                              <p className="text-sm text-white m-0 p-0 font-thin">
                                Colaboração
                              </p>
                            </div>

                            <div
                              onClick={() => setBehavior('inovacao')}
                              className={`${
                                !behavior || behavior === 'inovacao'
                                  ? ''
                                  : 'opacity-50'
                              } elem-2 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                            >
                              <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                                <Inovacao size={'100%'} />
                              </div>
                              <p className="text-sm text-white m-0 p-0 font-thin">
                                Inovação
                              </p>
                            </div>

                            <div
                              onClick={() => setBehavior('foco')}
                              className={`${
                                !behavior || behavior === 'foco'
                                  ? ''
                                  : 'opacity-50'
                              } elem-3 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                            >
                              <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                                <FocoNoCliente size={'100%'} />
                              </div>
                              <p className="text-sm text-white m-0 p-0 font-thin">
                                Foco no cliente
                              </p>
                            </div>

                            <div
                              onClick={() => setBehavior('resolutividade')}
                              className={`${
                                !behavior || behavior === 'resolutividade'
                                  ? ''
                                  : 'opacity-50'
                              } elem-4 ring-2 bg-maastricht-blue-2 flex-1 cursor-pointer ring-blue-violet-crayola p-2 pt-3 rounded-xl text-center flex flex-col items-center`}
                            >
                              <div className="w-12 h-12 lg:w-16 lg:h-16 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-2">
                                <Resolutividade size={'100%'} />
                              </div>
                              <p className="text-sm text-white m-0 p-0 font-thin">
                                Protagonismo
                              </p>
                            </div>
                          </div>
                          {!!behavior && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: GetBehaviorDescription(behavior),
                              }}
                              className="text-elem font-thin text-xs text-electric-blue block leading-tight text-center px-5"
                            ></div>
                          )}
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <>
                        {!hasEnoughCoins('uau') &&
                          !hasEnoughCoins('mandou') &&
                          !hasEnoughCoins('valeu') && (
                            <div>
                              <p className="text-yellow p-2 text-center border border-yellow rounded-lg lg:rounded-full mx-8">
                                Você não possui saldo de Moedas BB para
                                reconhecer. Aguarde até a próxima distribuição.
                              </p>
                            </div>
                          )}

                        {(hasEnoughCoins('uau') ||
                          hasEnoughCoins('mandou') ||
                          hasEnoughCoins('valeu')) && (
                          <>
                            <div className="block font-thin text-white text-base leading-none mb-5 px-6 lg:px-0 lg:mx-8">
                              Escolha a <strong>Moeda BB</strong> que você
                              deseja enviar:
                            </div>

                            <div>
                              <div
                                className={`w-full ${
                                  !!coin
                                    ? 'grid-items-reconhecer has-item'
                                    : 'grid-items-reconhecer'
                                } moedas bg-oxford-blue-2 rounded-lg w-full py-4 px-8 lg:flex lg:flex-row items-center justify-between mt-5 text-xs lg:text-md text-yellow`}
                              >
                                <button
                                  disabled={!hasEnoughCoins('uau')}
                                  onClick={() =>
                                    hasEnoughCoins('uau')
                                      ? setCoin('uau')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'uau' ? '' : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('uau')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-1 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-col lg:flex-row items-center lg:space-x-2 mb-3">
                                    <Uau size={24} yellow />
                                    <p className="font-bold text-xs leading-tight">
                                      #UAU
                                    </p>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('uau')
                                      ? 'Saldo insuficiente'
                                      : '50 pontos'}
                                  </p>
                                </button>
                                <button
                                  disabled={!hasEnoughCoins('mandou')}
                                  onClick={() =>
                                    hasEnoughCoins('mandou')
                                      ? setCoin('mandou')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'mandou'
                                      ? ''
                                      : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('mandou')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-2 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-col lg:flex-row items-center lg:space-x-2 mb-3">
                                    <MandouBem size={24} yellow />
                                    <div className="text-xs text-left leading-none">
                                      <div>#MANDOUBEM</div>
                                    </div>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('mandou')
                                      ? 'Saldo insuficiente'
                                      : '30 pontos'}
                                  </p>
                                </button>
                                <button
                                  disabled={!hasEnoughCoins('valeu')}
                                  onClick={() =>
                                    hasEnoughCoins('valeu')
                                      ? setCoin('valeu')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'valeu'
                                      ? ''
                                      : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('valeu')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-3 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-row items-center lg:space-x-2 mb-3">
                                    <Valeu size={24} yellow />
                                    <p className="text-xs leading-tight">
                                      #VALEU
                                    </p>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('valeu')
                                      ? 'Saldo insuficiente'
                                      : '10 pontos'}
                                  </p>
                                </button>

                                {!!coin && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: GetMoedaDescription(coin),
                                    }}
                                    className="text-elem font-thin text-xs text-electric-blue block leading-tight"
                                  ></div>
                                )}
                              </div>
                            </div>

                            <div className="lg:hidden">
                              <div
                                className={`w-full grid grid-cols-1 gap-y-4 moedas bg-oxford-blue-2 rounded-lg w-full py-4 px-8 lg:flex lg:flex-row items-center justify-between mt-5 text-xs lg:text-md text-yellow`}
                              >
                                <button
                                  disabled={!hasEnoughCoins('uau')}
                                  onClick={() =>
                                    hasEnoughCoins('uau')
                                      ? setCoin('uau')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'uau' ? '' : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('uau')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-1 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-col lg:flex-row items-center lg:space-x-2 mb-3">
                                    <Uau size={24} yellow />
                                    <p className="font-bold text-xs leading-tight">
                                      #UAU
                                    </p>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('uau')
                                      ? 'Saldo insuficiente'
                                      : '50 pontos'}
                                  </p>
                                </button>
                                <button
                                  disabled={!hasEnoughCoins('mandou')}
                                  onClick={() =>
                                    hasEnoughCoins('mandou')
                                      ? setCoin('mandou')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'mandou'
                                      ? ''
                                      : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('mandou')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-2 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-col lg:flex-row items-center lg:space-x-2 mb-3">
                                    <MandouBem size={24} yellow />
                                    <div className="text-xs text-left leading-none">
                                      <div>#MANDOUBEM</div>
                                    </div>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('mandou')
                                      ? 'Saldo insuficiente'
                                      : '30 pontos'}
                                  </p>
                                </button>
                                <button
                                  disabled={!hasEnoughCoins('valeu')}
                                  onClick={() =>
                                    hasEnoughCoins('valeu')
                                      ? setCoin('valeu')
                                      : null
                                  }
                                  className={`${
                                    !coin || coin === 'valeu'
                                      ? ''
                                      : 'opacity-50'
                                  } ${
                                    !hasEnoughCoins('valeu')
                                      ? 'grayscale text-white'
                                      : ''
                                  } disabled:opacity-50 elem-3 ring-2 bg-maastricht-blue-2 cursor-pointer ring-blue-violet-crayola p-3 rounded-xl text-center inline-flex flex-col justify-center items-center`}
                                >
                                  <div className="flex flex-row items-center lg:space-x-2 mb-3">
                                    <Valeu size={24} yellow />
                                    <p className="text-xs leading-tight">
                                      #VALEU
                                    </p>
                                  </div>
                                  <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                                    {!hasEnoughCoins('valeu')
                                      ? 'Saldo insuficiente'
                                      : '10 pontos'}
                                  </p>
                                </button>

                                {!!coin && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: GetMoedaDescription(coin),
                                    }}
                                    className="text-elem font-thin text-xs text-electric-blue block leading-tight"
                                  ></div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {step === 3 && target && behavior && coin && (
                      <div className="bg-oxford-blue-2 rounded-lg w-full py-4 px-8 block">
                        <div className="block font-thin text-white text-base leading-none mb-3">
                          Agora conte a{' '}
                          <strong className="uppercase">
                            {target?.extra?.nome_guerra
                              ? target?.extra?.nome_guerra
                              : target?.name?.split(' ')[0]}
                          </strong>{' '}
                          o motivo de ganhar{' '}
                          <span className="text-electric-blue">
                            {GetMoedaLabel(coin)}
                          </span>{' '}
                          em{' '}
                          <span className="text-electric-blue">
                            {GetBehaviorLabel(behavior)}
                          </span>
                          !
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="h-48 w-full bg-transparent">
                            <Editor
                              apiKey="jwzkyeee3alqgkm2a3zyf9e4to1lkzlejkyp9fkcfgnyhvg3"
                              init={{
                                plugins: 'emoticons',
                                toolbar: 'emoticons',
                                toolbar_location: 'bottom',
                                menubar: false,
                                resize: false,
                                min_height: 0,
                                height: '100%',
                                skin: 'oxide-dark',
                                content_css: '/assets/editor/darkmode.css',
                              }}
                              onEditorChange={(newValue) => {
                                setMessage(newValue);
                              }}
                              ref={(ref) => (messageRef.current = ref)}
                            />
                          </div>

                          {/* <textarea
                              ref={(ref) => (messageRef.current = ref)}
                              onChange={(e) => setMessage(e.target.value)}
                              className="h-28 w-full bg-transparent p-2"
                              style={{ fontSize: 16 }}
                            ></textarea> */}
                          {/* <button className="text-blue-light p-2 hidden md:block" onClick={toggleEmoji}>
                              <BiSmile size={24} />
                            </button> */}
                        </div>
                      </div>
                    )}

                    <div className="flex lg:block justify-center">
                      <div className="p-2 px-45 flex flex-row space-x-8 items-center justify-center">
                        {step === 0 &&
                          !target &&
                          !!searchResults &&
                          searchResults.length > 0 && (
                            <button
                              onClick={() => reset()}
                              className={`leading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}
                            >
                              <p className="font-bold text-sm leading-none uppercase">
                                Voltar
                              </p>
                            </button>
                          )}
                        {step > 0 && (
                          <button
                            onClick={() => setStep(step - 1)}
                            className={`leading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              Voltar
                            </p>
                          </button>
                        )}
                        {step < 3 && (
                          <button
                            disabled={
                              (step === 0 && !target) ||
                              (step === 1 && !behavior) ||
                              (step === 2 && !coin) ||
                              (step === 3 && !message)
                            }
                            onClick={() => setStep(step + 1)}
                            className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              Continuar
                            </p>
                          </button>
                        )}
                        {step === 3 && (
                          <button
                            disabled={!message}
                            onClick={() => handleSubmit()}
                            className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                          >
                            <p className="font-bold text-sm leading-none uppercase">
                              Continuar
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {loading && !submitted && (
                  <div className="space-y-4 mt-12">
                    <div className="flex flex-row items-center justify-center text-xl text-yellow animate-pulse">
                      <div></div>
                      Enviando reconhecimento...
                    </div>
                  </div>
                )}
              </div>
            </Box>
          )}

          {submitted && (
            <div className="border-2 rounded-3xl p-3 relative bg-oxford-blue border-electric-blue p-8 flex flex-col w-[324px] lg:max-w-screen-md justify-center items-center">
              <button
                className="absolute -top-5 -right-5 button-close"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <IoMdCloseCircle size={48} className="text-slate-blue" />
              </button>

              <div className="flex flex-col items-center justify-center">
                {!submitError && (
                  <>
                    <div className="flex items-center justify-center mb-6">
                      <img
                        src={getImgFeedback(coin || '')}
                        alt={
                          coin === 'mandou'
                            ? 'Mandou bem!'
                            : coin === 'uau'
                            ? 'Uau!'
                            : 'Valeu!'
                        }
                        className="w-[173px] block"
                      />
                    </div>
                    <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">
                      {coin === 'mandou'
                        ? 'Mandou bem!'
                        : coin === 'uau'
                        ? 'Uau!'
                        : 'Valeu!'}
                    </h1>
                    <p className="text-sm font-white font-light text-center block">
                      Você reconheceu um colega. Agradecemos por ajudar a tornar
                      o BB um ambiente colaborativo e agradável para trabalhar.
                    </p>
                  </>
                )}

                {submitError && (
                  <>
                    <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">
                      Não deu pra enviar este reconhecimento!
                    </h1>
                    <p className="text-sm font-white font-light text-center block mb-8">
                      {submitError}
                    </p>
                    <button
                      onClick={() => reset()}
                      className={`leading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}
                    >
                      Tentar novamente
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    />
  );
}
