/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';

import { useAuth } from 'context/AuthContext';

import Logo from 'assets/img/logo-festa-junina.svg';
import MegaPhone from 'assets/img/megaphone-icon.svg';

import Avatar, { genConfig } from 'funifier-nice-avatar';

import './styles.scss';
import { TbMailFast } from 'react-icons/tb';
import { BiInfoCircle, BiMenu } from 'react-icons/bi';
import Button from 'components/ui/button';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
// import { Tutorial } from 'components/tutorial';
import { SeloHelpModal } from 'components/selo-help-modal';
import { useModal } from 'context/ModalContext';
import { Countdown } from 'components/countdown';
import {
  Colaboracao,
  FocoNoCliente,
  Inovacao,
  MandouBem,
  Resolutividade,
  Uau,
  Valeu,
} from 'components/icons';
import { SeloMoedasModal } from 'components/moeda-help-modal';
import { NotAllowed } from 'pages/NotAllowed';
import {
  getEquieGestor,
  getKPIGaleriaReconhecimento,
  getKPIPointsOnline,
  getKPIPontosCultura,
  getPlayerInfo,
  getTaskCycles,
  getUserGroup,
} from 'services/dashboard.service';
import { ComportamentoHelpModal } from 'components/comportamento-help-modal';
import { PontosHelpModal } from 'components/pontos-help-modal';
// import { getCompetitionLeaders } from 'services/leaderboard.service';
import Tippy from '@tippyjs/react';
import {
  GroupDetailInterface,
  RankingKPI,
  UserGroupInterface,
} from 'types/user';
import { TaskCycles } from './components/task-cycles';

import { getChallenges } from 'services/challenges.service';
import { getReconhecimentos } from 'services/achievement.service';
import UserTutorial from 'components/user-tutorial';
import {
  checkNotifications,
  getAllNotifications,
  getMyNotifications,
} from 'services/notifications.service';
import { useWebsocketNotification } from 'context/WebsocketNotificationContext';
import NewFeatures from 'components/new-features';
import { UserAvatar } from 'components/user-avatar';
import { VERSION } from 'utils/constants';
import { GroupDetailModal } from 'components/group-detail-modal';
import { Company } from './components/company';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import ArticlePlaceholderSmall from 'components/ui/article-placeholder-small';
import { UserInfo } from 'types/user';
import { MdContentPasteSearch } from 'react-icons/md';
// import Decoration from 'components/decoration';
// import { GroupDetailModal } from 'components/group-detail-modal';
// import { PesquisaModal } from 'components/pesquisa-modal';
// import TutorialChallenges from 'components/tutorial-challenges';
// import Header from 'components/header';
// import { Tutorial } from 'components/tutorial';

const defautConfig = genConfig();

const dummyRankingKPI = { position: 0, total_positions: 0 };
const dummyKPI: any = {
  position: '---',
  uau: '---',
  mandou: '---',
  valeu: '---',
};

export function Dashboard() {
  const { user, refreshUser, signOut, currentCycle, isMSTeams } = useAuth();
  const { setMessage } = useWebsocketNotification();
  const [avatarConfig, setAvatarConfig] = useState(defautConfig);
  const [isGestor, setIsGestor] = useState(false);
  const [hasTeam, setHasTeam] = useState(false);
  const [groupInfo, setGroupInfo] = useState<any>();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [lastUpdate, setLastUpdate] = useState<any>();
  const [load, setLoad] = useState(true);

  const [selosBB, setSelosBB] = useState<any>();

  // const [tasks, setTasks] = useState<any>();
  // const [taskCycles, setTaskCycles] = useState<any>();
  // const [loadingTasks, setLoadingTasks] = useState(true);

  const [showTutorial, setShowTutorial] = useState(false);
  const [closeFeatures, setCloseFeatures] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [destaqueCultura, setDestaqueCultura] = useState({
    foco: dummyKPI,
    colaboracao: dummyKPI,
    inovacao: dummyKPI,
    resolutividade: dummyKPI,
  });

  const [pontosCultura, setPontosCultura] = useState<{
    foco: number;
    colaboracao: number;
    inovacao: number;
    resolutividade: number;
    total: number;
    geral: number;
    ranking_galeria: any;
  }>({
    foco: 0,
    colaboracao: 0,
    inovacao: 0,
    resolutividade: 0,
    total: 0,
    geral: 0,
    ranking_galeria: undefined,
  });

  const [galeriaReconhecimento, setGaleriaReconhecimento] =
    useState<RankingKPI>(dummyRankingKPI);

  function handleNavMobile() {
    document.dispatchEvent(new CustomEvent('openNavMobile'));
  }

  function messageLevel() {
    return (
      <div>
        <p>
          A sua colocação é calculada sobre a quantidade total de pessoas do seu
          grupo.
        </p>
        <br />
        <p>
          Estarão na lista final da “Galeria do Reconhecimento”, somente os 30%
          melhores colocados do seu grupo (com arredondamento para cima).
        </p>
        <br />
        <p>
          A divulgação dos ganhadores será realizada em ordem alfabética. Apenas
          você poderá ver a sua colocação.
        </p>
      </div>
    );
  }

  function handleAvatarClick() {
    showModal('avatar', { config: avatarConfig });
  }

  // function getTaskPoints(task: any) {
  //   const myPoints = task.team.find((item: any) => item._id === user?._id);
  //   return myPoints.points || 0;
  // }

  function getPositionLabel(kpi: RankingKPI) {
    try {
      return (
        <div className="p-1 text-center">
          <p>
            Você está na colocação <b>{kpi.position}</b> de{' '}
            <b>{groupInfo.total}</b> dentro do seu grupo.{' '}
            {!!lastUpdate
              ? `Ranking atualizado em: ${format(
                  new Date(lastUpdate),
                  'd MMM yyyy, HH:mm',
                  {
                    locale: ptBR,
                  }
                )}.`
              : ''}
          </p>
          <p>
            Estarão na lista final desta mecânica "Galeria do Reconhecimento",
            somente os 30% melhores colocados, com arredondamento para cima.
          </p>

          <p>
            A divulgação dos ganhadores será realizada por ordem alfabética.
          </p>
          <p>A sua posição nesta mecânica está visível somente a você.</p>
        </div>
      );
    } catch (e) {
      return `---`;
    }
  }

  function getPositionCulturaLabel(kpi: any) {
    try {
      return (
        <div className="p-1 text-center">
          <p>
            Você está na colocação <b>{kpi.posicao}</b> de{' '}
            <b>{groupInfo.total}</b> dentro do seu grupo.{' '}
            {!!lastUpdate
              ? `Ranking atualizado em: ${format(
                  new Date(lastUpdate),
                  'd MMM yyyy, HH:mm',
                  {
                    locale: ptBR,
                  }
                )}.`
              : ''}
          </p>

          <p>
            Estarão na lista final desta mecânica "Destaques da Cultura" 5% dos
            participantes dentro do seu grupo, com arredondamento para cima, que
            receberem mais pontos em cada Comportamento da Cultura.
          </p>

          <p>
            A divulgação dos ganhadores será realizada por ordem alfabética.
          </p>
          <p>A sua posição nesta mecânica está visível somente a você.</p>
        </div>
      );
    } catch (e) {
      return `---`;
    }
  }

  function getMoedas(data: any, moeda: string) {
    try {
      return data[moeda];
    } catch (e) {
      return '---';
    }
  }

  function getPontosPorCultura(data: any[], behavior: string) {
    const dummy = {
      posicao: '---',
      uau: '---',
      mandou: '---',
      valeu: '---',
    };
    const pontos = data.find((item: any) => item.behavior === behavior);
    if (!pontos) {
      return dummy;
    }
    return pontos;
  }

  useEffect(() => {
    try {
      const locationState: any = location.state;
      if (locationState && locationState.direction) {
        if (locationState.direction === 'back') {
          refreshUser();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [location]);

  useEffect(() => {
    async function loadKPIComportamento() {
      try {
        const resultPointsOnline = await getKPIPointsOnline(user?._id);

        const _pontosCultura: any = {
          foco: 0,
          colaboracao: 0,
          inovacao: 0,
          resolutividade: 0,
          total: resultPointsOnline.total || 0,
          geral: resultPointsOnline.geral || 0,
          ranking_galeria: !!resultPointsOnline.ranking_galeria
            ? resultPointsOnline.ranking_galeria
            : undefined,
        };

        resultPointsOnline.positions?.forEach((position: any) => {
          _pontosCultura[position.behavior] = position.total;
        });

        setPontosCultura(_pontosCultura);

        const kpiPontosCultura = await getKPIPontosCultura(user?._id);
        setGaleriaReconhecimento({
          position: !!resultPointsOnline?.ranking_galeria?.position
            ? resultPointsOnline?.ranking_galeria?.position
            : 0,
          total: !!resultPointsOnline.geral ? resultPointsOnline.geral : 0,
        });
        setLastUpdate(kpiPontosCultura?.time || undefined);

        if (!!kpiPontosCultura?.positions?.length) {
          const _destaqueCultura: any = {
            foco: { posicao: '---' },
            colaboracao: { posicao: '---' },
            inovacao: { posicao: '---' },
            resolutividade: { posicao: '---' },
          };

          kpiPontosCultura.positions.forEach((position: any) => {
            _destaqueCultura[position.behavior].posicao = position.position;
          });

          setDestaqueCultura(_destaqueCultura);
        }
      } catch (e) {
        console.log(e);
      }
    }

    function updateAvatar() {
      if (user?.extra.avatar) {
        setAvatarConfig(user?.extra.avatar);
      }
    }

    async function getUserInfo() {
      try {
        // const tasksResults = await getTasks(user?._id);
        // setTasks(tasksResults);

        // const taskCycleResults = await getTaskCycles();
        // setTaskCycles(taskCycleResults);

        // // console.log(tasksResults);

        // setLoadingTasks(false);

        const playerInfo = await getPlayerInfo(user?._id);

        if (playerInfo) {
          setUserInfo(playerInfo);
          setIsGestor(
            playerInfo.tipo_ro === 'GERENCIAL' ||
              playerInfo.funcao === 'DIRETOR'
          );

          if (playerInfo.grupo) {
            setGroupInfo({
              ro: playerInfo.ro,
              ...playerInfo.grupo,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    function shouldShowTutorial() {
      if (
        (user?.extra.accept && !user?.extra.tutorial) ||
        !localStorage.getItem('tutorial')
      ) {
        setShowTutorial(true);
      }
    }

    async function loadChallenges() {
      try {
        const data = await getChallenges(`'extra.type':'selobb'`);
        const currentSelosBB = data.filter((item: any) => {
          return user?.challenges[item._id] >= 1;
        });
        setSelosBB(currentSelosBB);
      } catch (e) {}
    }

    async function loadTeam() {
      if (user?.extra?.id) {
        const data = await getEquieGestor(user.extra.id, '', 0, 1);

        if (data?.result?.length) setHasTeam(true);
        setLoad(false);
      }
    }

    if (!user || !currentCycle) return;

    loadTeam();
    loadChallenges();
    loadKPIComportamento();
    updateAvatar();
    getUserInfo();
    shouldShowTutorial();

    const returnURL = sessionStorage.getItem('return');

    if (returnURL) {
      sessionStorage.removeItem('return');
      navigate(returnURL);
    }
  }, [user, currentCycle]);

  useEffect(() => {
    async function loadNotifications() {
      if (userInfo) {
        const data = await getAllNotifications(
          userInfo?.cad_missao,
          user?.extra?.lastMessage
        );
        setMessage(data);
      }
    }

    if (!userInfo || !user) return;

    loadNotifications();
  }, [userInfo, user]);

  if (!user) return null;

  if (!user.extra.id) {
    return <NotAllowed />;
  }

  return (
    <>
      {/* <PesquisaModal /> */}
      <Outlet />

      <div className="flex flex-col pb-20 lg:pb-0 lg:flex-row mx-auto xl:container">
        <div className="p-6 lg:p-3 lg:w-1/3 flex flex-col items-center">
          <div className="w-full flex flex-row lg:flex-col lg:items-center justify-between my-2 lg:my-4">
            <div className="w-20 lg:w-72">
              <img src={Logo} alt="#JogaJunto BB" className="relative" />
            </div>
            <div className="lg:hidden" onClick={() => handleNavMobile()}>
              <BiMenu size={32} />
            </div>
          </div>

          <div className="mt-8 lg:mt-5 text-left lg:text-center w-full">
            <h1 className="text-3xl text-white font-semibold">
              Juntos fazemos <br />
              muito mais!
            </h1>
            {/* <p className="text-base lg:text-2xl font-light lg:font-normal leading-none">
              Jogue junto com a gente! <br />
              Seu reconhecimento <br /> vale muito.
            </p> */}
          </div>

          {load && (
            <div className="space-y-3 w-full flex flex-col items-center mt-10">
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
              <ArticlePlaceholderSmall />
            </div>
          )}

          {!load && (
            <div
              data-step="6"
              className="hidden lg:flex flex-col space-y-5 mt-10"
            >
              {/* <Link
                to="/convidar"
                className="text-2xl font-normal leading-none hover:text-yellow focus:text-yellow active:text-yellow text-yellow flex items-center justify-center space-x-2 mb-3"
              >
                <TbMailFast className="text-[2.5rem]" />{' '}
                <span className="text-2xl font-normal leading-none">
                  Convide seus amigos!
                </span>
              </Link> */}

              {/* <a
                href="https://forms.office.com.mcas.ms/pages/responsepage.aspx?id=BykM6tI4gUGHULCxkLYEQ86IL6aO8Y9IoaMZHcLVA5dUQlVaVUY4SFNIUUdaMU1HQ08yWUxWRkRFUS4u"
                target="_blank"
                rel="noreferrer"
                className="text-2xl font-normal leading-none hover:text-yellow focus:text-yellow active:text-yellow text-yellow flex items-center justify-center space-x-2 mb-3"
              >
                <MdContentPasteSearch className="text-[2.5rem]" />
                <span className="text-2xl font-normal leading-none">
                  Avalie o #JogaJuntoBB
                </span>
              </a> */}

              <Link
                to="/galeria"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Galeria do Reconhecimento
                  </Button>
                }
              />

              {/* <Link
              to="/destaques-cultura"
              children={
                <Button color="primary" className="p-2 w-full uppercase rounded-md">
                  Destaques da Cultura BB
                </Button>
              }
            /> */}

              <Link
                to="/extrato"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Extrato de pontos
                  </Button>
                }
              />

              {hasTeam && (
                <Link
                  to="/quadro-equipe"
                  children={
                    <Button
                      color="primary"
                      className="p-2 w-full uppercase rounded-md"
                    >
                      Minha Equipe
                    </Button>
                  }
                />
              )}

              {/* <Link
                to="/rede-joga-junto"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Rede Joga Junto
                  </Button>
                }
              /> */}

              {/* {isGestor ? (
              <Link
                to="/validar-destaque"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Realização Destaque
                  </Button>
                }
              />
            ) : null}
            {!isGestor ? (
              <Link
                to="/realizacao-destaque"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Realização Destaque
                  </Button>
                }
              />
            ) : null} */}
              {/*
            <Link
              to="/reconhecer"
              children={
                <Button
                  color="primary"
                  className="p-2 w-full uppercase rounded-md"
                >
                  Emitir Moedas BB
                </Button>
              }
            /> */}

              {/* <Link
              to="/experiencias"
              children={
                <Button color="primary" className="p-2 w-full uppercase rounded-md">
                  Catálogo de experiências
                </Button>
              }
            /> */}

              <Link
                to="/regulamento"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Regras do jogo
                  </Button>
                }
              />

              <Link
                to="/manifesto"
                children={
                  <Button
                    color="primary"
                    className="p-2 w-full uppercase rounded-md"
                  >
                    Nosso Manifesto
                  </Button>
                }
              />

              {!isMSTeams && (
                <Button
                  onClick={() => signOut()}
                  color="outlinePurple"
                  className="p-2 w-full uppercase rounded-md text-white"
                >
                  Sair do jogo
                </Button>
              )}

              <div className="text-center text-xs mt-8 text-white text-opacity-90">
                {VERSION}
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 p-6 lg:p-5 2xl:p-10 space-y-5">
          <div
            data-step="1"
            className="flex flex-row items-center border-2 flex-wrap md:flex-nowrap border-electric-blue bg-oxford-blue rounded-2xl lg:rounded-[20px] px-6 lg:px-4 py-8 lg:py-4 justify-between"
          >
            <div className="lg:flex-none flex flex-row items-center h-full lg:border-r-2 border-electric-blue border-solid lg:-my-4 lg:p-4 lg:pr-6 space-x-4 lg:space-x-0">
              <div
                onClick={() => handleAvatarClick()}
                className="cursor-pointer hover:animate-wiggle hover:ring-2 ring-white w-16 h-16 lg:w-20 lg:h-20 rounded-full"
              >
                <UserAvatar
                  data={{ ...user, ...user.extra }}
                  responsive={true}
                />
                {/* {(!user.image || !user.image.medium || user.extra?.avatar_type === 0) && <Avatar className="w-full h-full" {...avatarConfig} />}
                {user.extra?.avatar_type === 1 && user.image && user.image.medium && <img src={user.image.medium.url} className="rounded-full overflow-hidden w-full h-full" alt="" />} */}
              </div>
              <div className="lg:p-3 flex flex-col leading-none">
                <div className="flex items-center">
                  <h3 className="text-white text-xl lg:text-2xl font-semibold leading-none mr-2">
                    {user.name.split(' ')[0]}
                  </h3>
                  <span className="text-white font-thin text-xs lg:text-lg leading-none">
                    ({user._id})
                  </span>
                </div>

                {/* <Tippy arrow={false} className="hidden lg:block bg-blue-light drop-shadow-lg text-blue-dark" allowHTML={true} content={messageLevel()}>
                  <div className="flex items-center space-x-2 lg:space-x-4 mt-1 lg:mt-3">
                    <p className="text-xs lg:text-lg font-thin">
                      Nível: <strong className="text-semibold text-xs lg:text-lg text-blue-light">Conectado</strong>
                    </p>
                    <BiInfoCircle className="color-[#C2D2FF] text-base lg:text-2xl" />
                  </div>
                </Tippy> */}

                {!!groupInfo && (
                  <div
                    className="hidden lg:flex flex-col 2xl:flex-row flex-wrap 2xl:items-center  leading-none space-x-1 text-white text-lg mt-2 cursor-pointer"
                    onClick={() => showModal('group-detail')}
                  >
                    <div className="text-md p-1 px-4 rounded-2xl bg-maastricht-blue text-electric-blue mb-3 2xl:mb-0">
                      GRUPO: {groupInfo.nome}
                    </div>
                    {/* <div className="font-thin">{groupInfo.nome}</div> */}
                    <div className="flex items-center space-x-2">
                      <span className="font-thin">({groupInfo.total}</span>{' '}
                      funcis)
                      <BiInfoCircle className="color-[#C2D2FF] text-base lg:text-2xl" />
                    </div>

                    {/* <div className="text-md p-1 px-4 rounded-2xl bg-maastricht-blue text-electric-blue">{groupInfo.ro}</div> */}
                  </div>
                )}
              </div>
            </div>

            {/* <div
              onClick={() => showModal('help-selo-bb')}
              className="flex flex-col items-center lg:p-2 hover:cursor-pointer hover:ring-2 hover:ring-white hover:rounded-xl"
            >
              <p className="text-yellow lg:mb-2">
                <b>Selo BB</b>
              </p>

              {!selosBB || !selosBB.length ? (
                <div className="w-12 h-12 rounded-full border border-dashed border-white flex items-center justify-center">
                  <TbQuestionMark size={36} />
                </div>
              ) : null}
              {!!selosBB && selosBB.length ? (
                <div className="w-16 h-16 rounded-full animate-wiggle overflow-hidden">
                  <img src={selosBB[0].badgeUrl} className="w-full h-full object-fit" alt={selosBB[0].challenge} />
                </div>
              ) : null}
            </div> */}

            <hr className="border-electric-blue w-full my-6 block lg:hidden" />

            {!!groupInfo && (
              <>
                <div
                  className="flex w-full lg:hidden flex-col items-center justify-between text-center leading-none space-x-2 text-white text-sm"
                  onClick={() => showModal('group-detail')}
                >
                  <div className="text-md p-1 px-4 rounded-2xl bg-maastricht-blue text-electric-blue mb-3 2xl:mb-0">
                    GRUPO: {groupInfo.nome}
                  </div>
                  <div className="font-thin flex space-x-2">
                    (<span className="font-light">{groupInfo.total}</span>{' '}
                    funcis)
                    <BiInfoCircle className="color-[#C2D2FF] text-base lg:text-2xl" />
                  </div>
                  {/* <div className="text-md p-1 px-2 rounded-full bg-maastricht-blue text-electric-blue font-light">{groupInfo.ro}</div> */}
                </div>

                <hr className="border-electric-blue w-full my-6 block lg:hidden" />
              </>
            )}

            {/* {!!groupInfo && (
              <div className="w-full lg:hidden">
                <div className="leading-none flex flex-row items-center justify-between leading-none space-x-1">
                  <div className="text-lg font-bold text-yellow flex-1">{groupInfo.nome}</div>
                  <div className="text-lg p-1 border rounded-lg border-yellow border-opacity-25">{groupInfo.ro}</div>
                  <div className="text-right flex-1">
                    <div className="text-lg leading-none text-yellow">{groupInfo.total}</div>
                    <span>funcis</span>
                  </div>
                </div>
              </div>
            )} */}

            <div className="flex flex-1 items-center justify-evenly pt-3">
              <div
                onClick={() => showModal('help-pontos')}
                className="hidden lg:block cursor-pointer hover:ring-2 rounded-lg ring-white py-1 px-2"
              >
                <p className="text-yellow leading-none p-0 mb-3  lg:text-md lg:uppercase">
                  <b>Meus Pontos</b>
                </p>
                <div className="bg-maastricht-blue text-center text-blue-light rounded-full py-1 px-2 lg:py-1 lg:px-3 text-2xl lg:text-xl lg:text-3xl font-bold">
                  {pontosCultura.geral
                    ? Intl.NumberFormat('pt-BR').format(
                        Math.ceil(pontosCultura.geral)
                      )
                    : '0'}
                </div>
              </div>

              <Tippy
                arrow={false}
                className="hidden lg:block bg-blue-light drop-shadow-lg text-blue-dark"
                allowHTML={true}
                content={getPositionLabel(galeriaReconhecimento)}
              >
                <div className="hidden lg:flex flex-col items-end lg:items-center">
                  <p className="mb-3 text-right lg:text-center leading-none -mt-[15px] uppercase relative">
                    <BiInfoCircle className="mr-1 inline-block lg:hidden" />
                    <b className="lg:text-md block  text-yellow text-center">
                      Galeria de <br />
                      Reconhecimento
                    </b>
                    <BiInfoCircle className="hidden lg:inline-block text-gray-light text-lg absolute -right-6 top-2" />
                  </p>

                  <div className="min-w-[100px] bg-maastricht-blue text-center text-blue-light rounded-full py-1 px-2 lg:py-1 lg:px-3 text-2xl lg:text-xl lg:text-3xl font-bold">
                    {galeriaReconhecimento.position
                      ? `${galeriaReconhecimento.position}º`
                      : '---'}
                  </div>
                </div>
              </Tippy>
            </div>

            <div className="flex items-end justify-between lg:hidden w-full">
              <div
                onClick={() => showModal('help-pontos')}
                className="flex items-center justify-end flex-col"
              >
                <p className="text-yellow font-semibold text-xs uppercase leading-none p-0 mb-2 block">
                  Meus Pontos
                </p>
                <div className="bg-maastricht-blue px-4 py-2 text-center text-electric-blue rounded-full font-semibold text-sm">
                  {pontosCultura.geral
                    ? Intl.NumberFormat('pt-BR').format(
                        Math.ceil(pontosCultura.geral)
                      )
                    : '0'}
                </div>
              </div>

              <Tippy
                arrow={false}
                className="bg-blue-light drop-shadow-lg text-blue-dark "
                allowHTML={true}
                content={getPositionLabel(galeriaReconhecimento)}
              >
                <div className="flex items-center justify-end flex-col">
                  <p className="text-yellow font-semibold text-xs uppercase leading-none p-0 mb-2 mr-5 flex items-center justify-center text-center relative">
                    Galeria de <br />
                    Reconhecimento{' '}
                    <BiInfoCircle className="absolute -right-6 top-2 inline-block text-white text-xl" />
                  </p>

                  <div className="bg-maastricht-blue min-w-[60px] px-4 py-2 text-center text-electric-blue rounded-full font-semibold text-sm">
                    {galeriaReconhecimento.position
                      ? `${galeriaReconhecimento.position}º`
                      : '---'}
                  </div>
                </div>
              </Tippy>
            </div>
          </div>

          {/* <TutorialChallenges /> */}

          <div
            data-step="2"
            className="border-2 border-electric-blue bg-oxford-blue rounded-2xl lg:rounded-[20px] px-6 lg:px-8 py-8 flex flex-col space-y-4"
          >
            <div className="text-white font-normal text-xl lg:text-2xl leading-none mb-6 lg:mb-4 text-left">
              Reconheça seus colegas com moedas BB
            </div>

            <div className="lg:flex  lg:space-x-8">
              <div className="lg:w-2/3 mb-8 lg:mb-0">
                <h4 className="text-white font-thin lg:font-normal text-lg mb-2 lg:mb-4 leading-none">
                  Saldo total para enviar reconhecimentos:{' '}
                  <span className="text-yellow">
                    {user?.point_categories?.point_behavior || 0} ponto
                    {!isNaN(user?.point_categories?.point_behavior) &&
                    user?.point_categories?.point_behavior !== 1
                      ? 's'
                      : ''}
                  </span>
                </h4>

                {currentCycle?.status === 'open' ? (
                  <p className="block lg:hidden mb-6 text-xs font-thin text-white">
                    Seu saldo atual expira em:{' '}
                    <strong className="font-bold">
                      <Countdown
                        deadline={new Date(currentCycle.date_finish)}
                      />
                    </strong>
                  </p>
                ) : null}

                <div
                  data-step="3"
                  className="flex lg:grid lg:grid-cols-3 lg:grid-rows-1 lg:gap-6 flex-col items-center space-y-4 lg:space-y-0 text-center text-yellow font-bold rounded-lg lg:hover:ring-2 lg:hover:ring-white hover:cursor-pointer"
                  onClick={() => showModal('help-moedas-bb')}
                >
                  <div className="box-coins relative bg-maastricht-blue rounded-lg p-4 flex flex-col justify-center items-center w-[165px] lg:w-auto">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="coin flex-1">
                        <Uau yellow />
                      </div>
                      <p className="font-light 2xl:font-semibold text-xs leading-tight">
                        #UAU
                      </p>
                    </div>
                    <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                      50 pontos
                    </p>
                  </div>
                  <div className="box-coins relative bg-maastricht-blue rounded-lg p-4 flex flex-col justify-center items-center w-[165px] lg:w-auto">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="coin mandou flex-1">
                        <MandouBem yellow />
                      </div>
                      <div className="font-light 2xl:font-semibold text-xs leading-tight">
                        #MANDOUBEM
                      </div>
                    </div>
                    <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                      30 pontos
                    </p>
                  </div>
                  <div className="box-coins relative bg-maastricht-blue rounded-lg p-4 flex flex-col justify-center items-center w-[165px] lg:w-auto">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="coin flex-1">
                        <Valeu yellow />
                      </div>
                      <p className="font-light 2xl:font-semibold text-xs leading-tight">
                        #VALEU
                      </p>
                    </div>
                    <p className="text-xs font-light leading-none bg-prussian-blue py-1 px-3 rounded-full">
                      10 pontos
                    </p>
                  </div>
                </div>

                {currentCycle?.status === 'open' ? (
                  <p className="hidden lg:block mt-3 text-sm font-thin text-white">
                    Seu saldo atual expira em:{' '}
                    <strong className="font-bold">
                      <Countdown
                        deadline={new Date(currentCycle.date_finish)}
                      />
                    </strong>
                  </p>
                ) : null}
              </div>

              {currentCycle?.status === 'open' ? (
                <div className="flex-1 flex flex-col text-center items-center">
                  <p className="text-sm font-thin text-white mb-4">
                    Deseja reconhecer <br className="hidden lg:block" />
                    algum colega?
                  </p>
                  <Button
                    className="uppercase py-2 px-5 rounded w-auto"
                    onClick={() => navigate('/reconhecer')}
                  >
                    Enviar Moedas BB
                  </Button>
                </div>
              ) : null}
            </div>
          </div>

          <div
            data-step="4"
            className="border-2 border-electric-blue bg-oxford-blue rounded-2xl lg:rounded-[20px] px-6 lg:px-8 py-8 flex flex-row items-center justify-between"
          >
            <div className="w-full">
              <div className="text-white font-normal text-xl lg:text-2xl leading-none mb-2 lg:mb-4 text-left">
                Dimensões do Comportamento
              </div>

              <p className="font-light mb-8 lg:mb-0 text-left block">
                Meus pontos recebidos em cada comportamento e minha posição
                dentro dos{' '}
                <span className="text-yellow">Destaques da Cultura</span>
              </p>

              <div className="grid grid-cols-2 gap-8 lg:flex lg:flex-row items-start lg:items-center justify-between lg:mt-5 lg:px-10">
                <div
                  className={`${
                    !pontosCultura.colaboracao || pontosCultura.colaboracao <= 0
                      ? 'opacity-60'
                      : ''
                  } text-center flex flex-col items-center`}
                >
                  <Tippy
                    arrow={false}
                    className="bg-blue-light drop-shadow-lg text-blue-dark"
                    allowHTML={true}
                    content={getPositionCulturaLabel(
                      destaqueCultura.colaboracao
                    )}
                  >
                    <b className="text-sm lg:text-2xl font-normal lg:font-semibold mb-2 lg:mb-0 relative">
                      {destaqueCultura.colaboracao.posicao
                        ? `${destaqueCultura.colaboracao.posicao}º`
                        : '---'}
                      <BiInfoCircle className="bottom-0 -right-5 absolute text-yellow text-lg inline-block" />
                    </b>
                  </Tippy>
                  <div
                    onClick={() =>
                      showModal('comportamento-help', 'colaboracao')
                    }
                    className="hover:ring-4 cursor-pointer w-14 lg:w-20 h-14 lg:h-20 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-3 lg:mb-2"
                  >
                    <Colaboracao size={'100%'} />
                  </div>
                  <b className="text-xs lg:text-md font-thin lg:font-normal mb-3 lg:mb-0 mt-0 lg:mt-2">
                    COLABORAÇÃO
                  </b>
                  <div className="bg-maastricht-blue text-white p-3 rounded-lg mt-0 lg:mt-3">
                    <span className="font-thin">
                      <b className="mr-1">{pontosCultura.colaboracao}</b>pts
                    </span>
                  </div>
                </div>

                <div
                  className={`${
                    !pontosCultura.inovacao || pontosCultura.inovacao <= 0
                      ? 'opacity-60'
                      : ''
                  } text-center flex flex-col items-center`}
                >
                  <Tippy
                    arrow={false}
                    className="bg-blue-light drop-shadow-lg text-blue-dark"
                    allowHTML={true}
                    content={getPositionCulturaLabel(destaqueCultura.inovacao)}
                  >
                    <b className="text-sm lg:text-2xl font-normal lg:font-semibold mb-2 lg:mb-0 relative">
                      {destaqueCultura.inovacao.posicao
                        ? `${destaqueCultura.inovacao.posicao}º`
                        : '---'}
                      <BiInfoCircle className="bottom-0 -right-5 absolute text-yellow text-lg inline-block" />
                    </b>
                  </Tippy>
                  <div
                    onClick={() => showModal('comportamento-help', 'inovacao')}
                    className="hover:ring-4 cursor-pointer w-14 lg:w-20 h-14 lg:h-20 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-3 lg:mb-2"
                  >
                    <Inovacao size={'100%'} />
                  </div>
                  <b className="text-xs lg:text-md font-thin lg:font-normal mb-3 lg:mb-0 mt-0 lg:mt-2">
                    INOVAÇÃO
                  </b>
                  <div className="bg-maastricht-blue text-white p-3 rounded-lg mt-0 lg:mt-3">
                    <span className="font-thin">
                      <b className="mr-1">{pontosCultura.inovacao}</b>pts
                    </span>
                  </div>
                </div>

                <div
                  className={`${
                    !pontosCultura.foco || pontosCultura.foco <= 0
                      ? 'opacity-60'
                      : ''
                  } text-center flex flex-col items-center`}
                >
                  <Tippy
                    arrow={false}
                    className="bg-blue-light drop-shadow-lg text-blue-dark"
                    allowHTML={true}
                    content={getPositionCulturaLabel(destaqueCultura.foco)}
                  >
                    <b className="text-sm lg:text-2xl font-normal lg:font-semibold mb-2 lg:mb-0 relative">
                      {destaqueCultura.foco.posicao
                        ? `${destaqueCultura.foco.posicao}º`
                        : '---'}
                      <BiInfoCircle className="bottom-0 -right-5 absolute text-yellow text-lg inline-block" />
                    </b>
                  </Tippy>
                  <div
                    onClick={() => showModal('comportamento-help', 'foco')}
                    className="hover:ring-4 cursor-pointer w-14 lg:w-20 h-14 lg:h-20 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-3 lg:mb-2"
                  >
                    <FocoNoCliente size={'100%'} />
                  </div>
                  <b className="text-xs lg:text-md font-thin lg:font-normal mb-3 lg:mb-0 mt-0 lg:mt-2">
                    FOCO NO CLIENTE
                  </b>
                  <div className="bg-maastricht-blue text-white p-3 rounded-lg mt-0 lg:mt-3">
                    <span className="font-thin">
                      <b className="mr-1">{pontosCultura.foco}</b>pts
                    </span>
                  </div>
                </div>

                <div
                  className={`${
                    !pontosCultura.resolutividade ||
                    pontosCultura.resolutividade <= 0
                      ? 'opacity-60'
                      : ''
                  } text-center flex flex-col items-center`}
                >
                  <Tippy
                    arrow={false}
                    className="bg-blue-light drop-shadow-lg text-blue-dark"
                    allowHTML={true}
                    content={getPositionCulturaLabel(
                      destaqueCultura.resolutividade
                    )}
                  >
                    <b className="text-sm lg:text-2xl font-normal lg:font-semibold mb-2 lg:mb-0 relative">
                      {destaqueCultura.resolutividade.posicao
                        ? `${destaqueCultura.resolutividade.posicao}º`
                        : '---'}
                      <BiInfoCircle className="bottom-0 -right-5 absolute text-yellow text-lg inline-block" />
                    </b>
                  </Tippy>
                  <div
                    onClick={() =>
                      showModal('comportamento-help', 'resolutividade')
                    }
                    className="hover:ring-4 cursor-pointer w-14 lg:w-20 h-14 lg:h-20 overflow-hidden bg-blue-light bg-opacity-10 ring-1 ring-yellow rounded-full mb-3 lg:mb-2"
                  >
                    <Resolutividade size={'100%'} />
                  </div>
                  <b className="text-xs lg:text-md font-thin lg:font-normal mb-3 lg:mb-0 mt-0 lg:mt-2">
                    PROTAGONISMO
                  </b>
                  <div className="bg-maastricht-blue text-white p-3 rounded-lg mt-0 lg:mt-3">
                    <span className="font-thin">
                      <b className="mr-1">{pontosCultura.resolutividade}</b>pts
                    </span>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block text-center text-white pt-4 px-4">
                Pontuação e classificação visível somente a você.
              </div>
            </div>
          </div>

          <div
            data-step="5"
            className="border-2 border-electric-blue bg-oxford-blue rounded-2xl lg:rounded-[20px] px-6 lg:px-8 py-8 flex flex-row items-center justify-between"
          >
            <div className="w-full">
              <div className="text-white font-normal text-xl lg:text-2xl leading-none mb-4 text-left">
                Realizações Destaque
              </div>
              <TaskCycles />
            </div>
          </div>

          <div
            data-step="5"
            className="border-2 border-electric-blue bg-oxford-blue rounded-2xl lg:rounded-[20px] px-6 lg:px-8 py-8 flex flex-row items-center justify-between"
          >
            <div className="w-full">
              <div className="text-white font-normal text-xl lg:text-2xl leading-none mb-4 text-left">
                Ações, desafios e campanhas
              </div>
              <Company />
            </div>
          </div>
        </div>
      </div>
      {showTutorial && closeFeatures && <UserTutorial />}
      {userInfo && (
        <NewFeatures player={userInfo} send={() => setCloseFeatures(true)} />
      )}
      <ComportamentoHelpModal />
      <SeloHelpModal />
      <SeloMoedasModal />
      <PontosHelpModal />
      <GroupDetailModal group={groupInfo} />
      {/* <Decoration /> */}
      {/* <GroupDetailModal totalGroup={groupInfo?.total} /> */}
    </>
  );
}
